import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Typography, Button, useTheme } from '@mui/material';
import { Add } from '@mui/icons-material';

import TeamTreeChart from './TeamTree'; // Assuming this component renders the team tree
import Loading from '../../../assets/Loading/Index';  // Assuming this is a loading spinner component

const TeamSection = ({ teams, employees, isLoadingTeams, handleOpenModal ,handleOpenNewMemberAdder}) => {
  // State to track the selected team
  const [selectedTeam, setSelectedTeam] = useState(teams ? teams[0] : null);
  const [treeView , setTreeView ] = useState(true)
  const [clicked, setClicked] = useState(false)
   // Handle team selection
  const handleTeamClick = (team) => {
    setSelectedTeam(team);
    setTreeView(false)
    setClicked(true)
  };

  useEffect(()=>{
         
    setTreeView(true)
    setClicked(false)
         
  },[clicked])


  const open = (id)=>{
    handleOpenNewMemberAdder(id)
  }

  
  return (
   <>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            padding: "1rem",
            borderBottom: "1px solid #dbe3e8",
          }}
        >
          <IconButton
            sx={{
              backgroundColor: useTheme().palette.primary.main,
              borderRadius: "15px",
              padding: "8px 16px",
              display: "flex",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#357ae8",
              },
            }}
            onClick={handleOpenModal}
          >
            <Add sx={{ color: "white", fontSize: "20px" }} />
            <Typography sx={{ ml: 1, color: "white", fontWeight: "500" }}>
              Add Team
            </Typography>
          </IconButton>
        </Grid>

        {/* Team List and Team Tree */}
        <Grid
          sx={{
            padding:1,
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            width: '100%'
          }}
          container
          spacing={2}
        >
          {/* Loading state */}
          {isLoadingTeams && <Loading />}
          <Grid item xs={12} sx={{
            display:'flex',
            flexDirection:'row',
            gap:1,
            flexWrap:'wrap'
            
          }}>
          {/* Render team list (nav items) */}
          {teams && teams?.map((team) => (
            <Grid  key={team.id}>
              <Button
                sx={{
                  padding: "0.5rem",
                  textAlign: "center",
                  backgroundColor: selectedTeam?._id === team._id ? useTheme().palette.primary.main : "#f0f0f0",
                  borderRadius: "10px",
                  boxShadow: selectedTeam?._id === team._id ? "0 4px 8px rgba(0, 0, 0, 0.5)" : "none",
                  "&:hover": {
                    backgroundColor: selectedTeam?._id === team._id ? "#357ae8" : "#e0e0e0",
                  },
                }}
                onClick={() => handleTeamClick(team)}
              >
                <Typography variant="h6" sx={{ color: selectedTeam?._id === team._id ? "white" : "black", fontSize:'10px' }}>
                  {team.teamName}
                </Typography>
              </Button>
            </Grid>
          ))}
</Grid>
          {/* Render Team Tree for the selected team */}
          { treeView && selectedTeam && (
            <Grid item xs={12} height={'57vh'}>
              <TeamTreeChart team={selectedTeam} employee={employees} open={open} />
            </Grid>
          )}
        </Grid>
        </>
  );
};

export default TeamSection;
