import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TextField, Button, Typography, Container, Grid } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch } from "react-redux";
import bgImage from '../../../assets/images/darkBg2.jpg';
import {
  setEmail,
  setPassword,
  setPhoneNumber,
} from "../../../redux/actions/userRagistration/index.js";
import { usePost } from "../../../helpers/axios/useApi.js";
import Loading from "../../../assets/Loading/Index.js";
import { Link } from "react-router-dom";

// Validation schema
const schema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  phone: yup.string().required("Phone number is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[@$!%*?&#]/,
      "Password must contain at least one special character"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  phoneOtp: yup.string().when("phoneVerified", {
    is: true,
    then: yup
      .string()
      .length(4, "OTP must be 4 digits")
      .required("OTP is required"),
  }),
  emailOtp: yup.string().when("emailVerified", {
    is: true,
    then: yup
      .string()
      .length(6, "OTP must be 6 digits")
      .required("OTP is required"),
  }),
});


/**
 * Returns an array of password strength requirements that are not met by the provided password.
 * The requirements are:
 * - minLength: The password must be at least 8 characters long.
 * - hasUppercase: The password must contain at least one uppercase letter.
 * - hasLowercase: The password must contain at least one lowercase letter.
 * - hasNumber: The password must contain at least one number.
 * - hasSpecial: The password must contain at least one special character.
 *
 * @param {string} password - The password to check against the requirements.
 * @returns {string[]} - An array of strings representing the unmet requirements.
 */

const validatePasswordStrength = (password) => {
  const strength = {
    minLength: password.length >= 8,
    hasUppercase: /[A-Z]/.test(password),
    hasLowercase: /[a-z]/.test(password),
    hasNumber: /[0-9]/.test(password),
    hasSpecial: /[@$!%*?&#]/.test(password),
  };

  return Object.keys(strength).filter((key) => !strength[key]);
};


/**
 * The UserVerification component is the first step in the registration process.
 * It is responsible for handling the email and phone OTP verification.
 * 
 * @param {function} onStageComplete - This function is called when the user has completed the OTP verification.
 * It is used to move the user to the next step in the registration process.
 * 
 * @returns {JSX.Element} - The JSX element representing the component.
 */

function UserVerification({ onStageComplete }) {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [otpSent, setOtpSent] = useState({ email: false, phone: false });
  const [emailVerified, setEmailVerified] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [emailVerificationStatus, setEmailVerificationStatus] = useState(null);
  const [phoneVerificationStatus, setPhoneVerificationStatus] = useState(null);
  const [passwordStrength, setPasswordStrength] = useState([]);
  const [resendCooldown, setResendCooldown] = useState({ email: 0, phone: 0 });
  const [disableEmail, setDisableEmail] = useState(false);
  const [disablePhone, setDisablePhone] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const emailOtpRefs = useRef([]);
  const phoneOtpRefs = useRef([]);
  const {mutate:sendPhoneOtpMutation} = usePost('one-time-password/send-phone');
  const {mutate:sendEmailOtpMutation} = usePost('one-time-password/send-email');
  const {mutate:verifyPhoneOtpMutation} = usePost('one-time-password/verify-phone');
  const {mutate:verifyEmailOtpMutation} = usePost('one-time-password/verify-email');
  useEffect(() => {
    const emailInterval = setInterval(() => {
      setResendCooldown((prev) => ({
        ...prev,
        email: Math.max(prev.email - 1, 0),
      }));
    }, 1000);

    const phoneInterval = setInterval(() => {
      setResendCooldown((prev) => ({
        ...prev,
        phone: Math.max(prev.phone - 1, 0),
      }));
    }, 1000);

    return () => {
      clearInterval(emailInterval);
      clearInterval(phoneInterval);
    };
  }, []);

  const onSubmit = async (data, event) => {
    event.preventDefault();

    if (emailVerified && phoneVerified) {
      try {
        dispatch(setEmail(data.email));
        const normalizePhone = (phone) => phone.replace(/[^\d]/g, '');

        // Normalize the input phone number 
        const normalizedPhoneOrEmail = normalizePhone(data.phone);
        dispatch(setPhoneNumber(normalizedPhoneOrEmail));
        dispatch(setPassword(data.password));
        onStageComplete();
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      toast.warn("Please complete email and phone verification");
    }
  };

  const handleSendOtp = async (type) => {
    const emailValue = getValues("email") || "";
    const phoneValue = getValues("phone") || "";
  
    if (type === "phone") {
      if (!isValidPhoneNumber(phoneValue)) {
        toast.warn("Please enter a valid phone number");
        return;
      }
      if (resendCooldown.phone > 0) {
        toast.warn(`Please wait ${resendCooldown.phone}s before resending OTP`);
        return;
      }
         
          setLoading(!loading);
          const res = await sendPhoneOtpMutation({ phoneNumber: phoneValue });
          
            toast.success(res?.data?.message);
            setOtpSent((prev) => ({ ...prev, phone: true })); // Ensure this line is executed
            setResendCooldown((prev) => ({ ...prev, phone: 150 }));
            setLoading(false);
          
         
       
    } else if (type === "email") {
     
        
        const isEmailValid = await trigger("email");
        
        if (isEmailValid) {
          setLoading(!loading);
            const res = await sendEmailOtpMutation({ email: emailValue });
       
            toast.success(res?.data?.message);
            setOtpSent((prev) => ({ ...prev, email: true })); // Ensure this line is executed
            setResendCooldown((prev) => ({ ...prev, email: 150 }));
            setLoading(false);
        
        }
    
    }
  };

  const handleVerify = async(type) => {
    const otp = getValues(`${type}Otp`);
    

    if (type === "phone") {
      setLoading(!loading);
      const res = await verifyPhoneOtpMutation({ phoneNumber: getValues("phone"), otp });

      if (res?.data !== null) {
        setPhoneVerified(true);
        setPhoneVerificationStatus("success");
        setDisablePhone(true);
        toast.success(res?.data?.message);
        setLoading(false);
      } else {
        setLoading(false);
        setPhoneVerified(false);
        setPhoneVerificationStatus("error");
        toast.error(res?.error?.error);
      }
    } else if (type === "email") {
      setLoading(!loading);
      const res = await verifyEmailOtpMutation({ email: getValues("email"), otp });
      if (res?.data !== null) {
        setEmailVerified(true);
        setEmailVerificationStatus("success");
        setDisableEmail(true);
        toast.success(res?.data?.message);
        setLoading(false);
      } else {
        setEmailVerified(false);
        setEmailVerificationStatus("error");
        toast.error(res?.error?.error);
        setLoading(false);
      }
    }
  };

  const handleOtpChange = (event, index, type) => {
    const { value } = event.target;
    const otpLength = type === "phone" ? 4 : 6;

    if (/^\d?$/.test(value)) {
      const currentOtp = getValues(`${type}Otp`) || "";
      const newOtp = currentOtp?.padEnd(otpLength, " ")?.split("");
      newOtp[index] = value;
      const updatedOtp = newOtp?.join("");

      setValue(`${type}Otp`, updatedOtp, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });

      if (value && index < otpLength - 1) {
        if (type === "phone") {
          phoneOtpRefs.current[index + 1]?.focus();
        } else if (type === "email") {
          emailOtpRefs.current[index + 1]?.focus();
        }
      } else if (value === "" && index > 0) {
        if (type === "phone") {
          phoneOtpRefs.current[index - 1]?.focus();
        } else if (type === "email") {
          emailOtpRefs.current[index - 1]?.focus();
        }
      }
    }
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setValue("password", password);
    setPasswordStrength(validatePasswordStrength(password));
  };

  const backgroundContainerStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    zIndex: 2,
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 1,
  };

  const formContainerStyle = {
    position: "absolute",
    zIndex: 999,
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    maxWidth: "600px",
    width: "100%",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  };

  return (
    <>
      
      <div style={backgroundContainerStyle}>

        <div style={overlayStyle} />
        {loading && <Loading/>}
        <ToastContainer />
        <Container component="main" style={formContainerStyle}>

          <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "30px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      label="Email"
                      type="email"
                      sx={{
                        color: "black",
                      }}
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ""}
                      disabled={disableEmail}
                    />
                  )}
                />
                
                {!emailVerified && (
                  <>
                    
                      <Button
                        variant="outlined"
                        onClick={() => handleSendOtp("email")}
                        style={resendCooldown.email > 0 ? { border: "none" , color:'red'} : { marginTop: "10px" }}
                        sx={{
                          
                        }}
                        disabled={disableEmail || resendCooldown.email > 0}
                      >
                        {resendCooldown.email > 0
                          ? `Resend in ${resendCooldown.email}s`
                          : "Send Email OTP"}
                        
                      </Button>
                    
                      {otpSent.email && (
                        <Grid container spacing={1} mt={0}>
                          {Array.from({ length: 6 }).map((_, index) => (
                            <Grid item xs={1.5} key={index}>
                              <TextField
                                inputRef={(el) =>
                                  (emailOtpRefs.current[index] = el)
                                }
                                variant="outlined"
                                fullWidth
                                type="text"
                                inputProps={{ maxLength: 1, pattern: "[0-9]*" }}
                                error={!!errors.emailOtp}
                                helperText={
                                  errors.emailOtp ? errors.emailOtp.message : ""
                                }
                                onChange={(e) =>
                                  handleOtpChange(e, index, "email")
                                }
                                disabled={disableEmail}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderRadius: "10px",
                                    height: "40px",
                                    width: "40px",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "10px",
                                  },
                                  "& .MuiInputBase-input": {
                                    textAlign: "center",
                                  },
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid>)}
                        {emailVerificationStatus && (
                          <Typography
                            variant="body2"
                            color={
                              emailVerificationStatus === "success"
                                ? "success.main"
                                : "error.main"
                            }
                          >
                            {emailVerificationStatus === "success" ? (
                              <>
                                <CheckCircleIcon /> OTP Verified Successfully
                              </>
                            ) : (
                              <>
                                <ErrorIcon /> Incorrect OTP
                              </>
                            )}
                          </Typography>
                        )}
                        {otpSent.email && !emailVerified && (
                          <Button
                            variant="outlined"
                            onClick={() => handleVerify("email")}
                            style={{ marginTop: "10px" 
                            }
                            }
                            disabled={disableEmail}
                          >
                            Verify Email OTP
                          </Button>
                        )}
                      </>
                    )}
                  
                
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      defaultCountry="IN"
                      style={{ height: "60px" }}
                      international
                      placeholder="Enter phone number"
                      error={!!errors.phone}
                      onChange={(value) => setValue("phone", value)}
                      value={getValues("phone")}
                      disabled={disablePhone}
                    />
                  )}
                />
                {!phoneVerified && (
                  <>
                   
                      <Button
                        variant="outlined"
                        onClick={() => handleSendOtp("phone")}
                        style={resendCooldown.phone > 0 ? { border: "none" , color:'red'} : { marginTop: "10px" }}
                        disabled={disablePhone || resendCooldown.phone > 0}
                      >
                        {resendCooldown.phone > 0
                          ? `Resend in ${resendCooldown.phone}s`
                          : "Send Phone OTP"}
                      </Button>
                  
                     {otpSent.phone && (
                        <Grid container spacing={1} mt={2}>
                          {Array.from({ length: 4 }).map((_, index) => (
                            <Grid item xs={1.5} key={index}>
                              <TextField
                                inputRef={(el) =>
                                  (phoneOtpRefs.current[index] = el)
                                }
                                variant="outlined"
                                fullWidth
                                type="text"
                                inputProps={{ maxLength: 1, pattern: "[0-9]*" }}
                                error={!!errors.phoneOtp}
                                helperText={
                                  errors.phoneOtp ? errors.phoneOtp.message : ""
                                }
                                onChange={(e) =>
                                  handleOtpChange(e, index, "phone")
                                }
                                disabled={disablePhone}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderRadius: "10px",
                                    height: "40px",
                                    width: "40px",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "10px",
                                  },
                                  "& .MuiInputBase-input": {
                                    textAlign: "center",
                                  },
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid>)}
                        {phoneVerificationStatus && (
                          <Typography
                            variant="body2"
                            color={
                              phoneVerificationStatus === "success"
                                ? "success.main"
                                : "error.main"
                            }
                          >
                            {phoneVerificationStatus === "success" ? (
                              <>
                                <CheckCircleIcon /> OTP Verified Successfully
                              </>
                            ) : (
                              <>
                                <ErrorIcon /> Incorrect OTP
                              </>
                            )}
                          </Typography>
                        )}
                        {otpSent.phone && !phoneVerified && (
                          <Button
                            variant="outlined"
                            onClick={() => handleVerify("phone")}
                            style={{ marginTop: "10px" }}
                            disabled={disablePhone}
                          >
                            Verify Phone OTP
                          </Button>
                        )}
                      </>
                    )}
                
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      label="Password"
                      type="password"
                      onChange={handlePasswordChange}
                      error={!!errors.password}
                      helperText={
                        errors.password ? errors.password.message : ""
                      }
                    />
                  )}
                />
                {passwordStrength.length > 0 && (
                  <Typography variant="body2" color="error.main" mt={2}>
                    <strong>
                      Password must meet the following requirements:
                    </strong>
                    <ul>
                      <li
                        style={{
                          color: passwordStrength.includes("minLength")
                            ? "green"
                            : "red",
                        }}
                      >
                        At least 8 characters
                      </li>
                      <li
                        style={{
                          color: passwordStrength.includes("hasUppercase")
                            ? "green"
                            : "red",
                        }}
                      >
                        At least one uppercase letter
                      </li>
                      <li
                        style={{
                          color: passwordStrength.includes("hasLowercase")
                            ? "green"
                            : "red",
                        }}
                      >
                        At least one lowercase letter
                      </li>
                      <li
                        style={{
                          color: passwordStrength.includes("hasNumber")
                            ? "green"
                            : "red",
                        }}
                      >
                        At least one number
                      </li>
                      <li
                        style={{
                          color: passwordStrength.includes("hasSpecial")
                            ? "green"
                            : "red",
                        }}
                      >
                        At least one special character
                      </li>
                    </ul>
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      label="Confirm Password"
                      type="password"
                      error={!!errors.confirmPassword}
                      helperText={
                        errors.confirmPassword
                          ? errors.confirmPassword.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                sx={{
                  padding:"10px 20px",
                  width:"40%",
                  
                }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!emailVerified || !phoneVerified}
                >
                  Next
                </Button>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '10px', textAlign: 'center' }}>
              <Typography variant="body2">
                Already have an account? <Link to="/login">Login here</Link>
              </Typography>
            </Grid>
            </Grid>
          </form>
        </Container>
      </div>
    </>
  );
}

export default UserVerification;
