import React, { useEffect, useState } from 'react';
import { useGet } from '../../helpers/axios/useApi';
import { useParams } from 'react-router-dom';
import { Grid, Card, CardMedia, Typography, Dialog, DialogActions, DialogContent, Button, DialogTitle } from '@mui/material';

function WorkHistory() {
    const id = useParams().id;
    const [files, setFiles] = useState([]);
    const { data: trackingData } = useGet('/employee/tracking-get', {
        employeeId: id
    });
    
    // State to handle full-screen modal visibility and the image to display
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    useEffect(() => {
        console.log(trackingData?.data?.data[0])
        if (trackingData?.data?.data) {
            // Validate and filter out invalid base64 files
            const validFiles = trackingData?.data?.data[0]?.files?.filter(file => isValidBase64Image(file));
            console.log(trackingData?.data?.data[0])
            setFiles(trackingData?.data?.data[0]?.files); // Set only valid base64 image data
        }
    }, [trackingData]);

    // Function to check if the base64 string is a valid image
    const isValidBase64Image = (file) => {
        // Check if the string starts with valid base64 image data (e.g., 'data:image/png;base64,')
        const regex = /^data:image\/(png|jpeg|jpg|gif);base64,/;
        return file && regex.test(file); // Validates base64 format for PNG, JPEG, JPG, or GIF
    };

    // Function to open the modal with the selected image
    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setOpen(true); // Open the modal
    };

    // Function to close the modal
    const handleClose = () => {
        setOpen(false);
        setSelectedImage(''); // Clear selected image
    };

    return (
        <div style={{ padding: '20px' }}>
            

            {/* MUI Grid layout for displaying images */}
            <Grid container spacing={2} height={'70vh'} overflow={'auto'}>
                {files?.map((file, index) => (
                    <Grid item xs={12} sm={6} md={2} key={index}>
                        <Card sx={{ height: '100%' , width: '100%'}}> 
                            <CardMedia
                                component="img"
                                width={"100%"}
                                height="100%"
                                sx={{
                                    objectFit: 'cover',
                                    width: '100%',
                                }}
                                image={file}  // Base64 image data as the image URL
                                alt={`Screenshot ${index + 1}`}
                                onClick={() => handleImageClick(file)}  // Open modal on click
                            />
                        </Card>
                    </Grid>
                ))}
            </Grid>

            {/* Full-screen image modal */}
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                
                <DialogContent>
                    <img
                        src={selectedImage}
                        alt="Full screen"
                        style={{ width: '100%', height: 'auto' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default WorkHistory;
