import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useGet, usePost } from "../../helpers/axios/useApi";
import { Box, Typography, Dialog, DialogActions, DialogContent, Button, TextField, CircularProgress, Menu, MenuItem, Tab, Tabs, IconButton, Popover } from '@mui/material';
import { useTheme } from '@emotion/react';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import axios from 'axios';
import { server_url } from '../../utils/server';
import { Grid } from '@mui/system';
import { useAuth } from '../../middlewares/auth';
import GetFileThumbnail from './getFileThumnail';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { Link, useParams } from 'react-router-dom';
moment.locale("en-GB");
const localizer = momentLocalizer(moment);
const today = new Date();
function CalendarActions({ size, getTimes }) {
  const [currentMonth, setCurrentMonth] = useState(moment().month() + 1);
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [events, setEvents] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);

  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showFeedbackInput, setShowFeedbackInput] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showContent, setShowContent] = useState(false); // For inline content view

  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 }); // Position for the menu
   const [startDate , setStartDate] = useState(null)
  const { data: getMeetings } = useGet('meetings/get', { month: currentMonth, year: currentYear });
  const { data: employees } = useGet("employee/all", {}, {}, { queryKey: "employees" });
  const [selectedTab, setSelectedTab] = useState(0);
  const [dailyWork, setDailyWork] = useState([]);
  const [feedback,setFeedback] = useState('')
  const [selected, setSelected] = useState(null)
  const idEMp = useParams().id
  const theme = useTheme();
  const {user} = useAuth()
 
  const { data: getDailyWorkData , refetch} = useGet('/emplyoee/daily-work/get', {
    employeeId: idEMp ?  idEMp : user?._id,
    date: selectedDate,
  });

  const handleGiveFeedback = usePost('/emplyoee/daily-work/update')
  useEffect(() => {
    if (getDailyWorkData?.data?.data) {
      setDailyWork(getDailyWorkData.data?.data);
    }
  }, [getDailyWorkData]);
  console.log(dailyWork)
  console.log(selectedDate)
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue); // Switch between tabs
  };
  useEffect(() => {
    if (getMeetings?.data?.data) {
      const transformedEvents = getMeetings.data.data.map(meeting => {
        const start = new Date(meeting.meetingDate);
        const end = new Date(start.getTime() + meeting.meetingDuration * 60000);

        return {
          id: meeting._id,
          title: `${meeting.meetingName} - ${meeting.meetingAgenda} - ${meeting.meetingHost} - ${meeting.meetingDuration} minutes`,
          start,
          end,
          color: getColorByTimeDifference(start),
        };
      });
      setEvents(transformedEvents);
    }
  }, [getMeetings]);
  const handlePopoverOpen = (event, file, id) => {
    setSelected(id)
    setAnchorEl(event.currentTarget);
    setSelectedFile(file);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedFile(null);
  };

  const handleFeedbackIconClick = () => {
    setShowFeedbackInput(!showFeedbackInput);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'file-thumbnail-popover' : undefined;
  const getColorByTimeDifference = (eventStart) => {
    const now = new Date();
    const timeDiff = eventStart - now;
    const minutesDiff = Math.floor(timeDiff / 60000);

    if (minutesDiff < 10 && minutesDiff >= 0) {
      return "#e82e3b";
    } else if (minutesDiff < 60 && minutesDiff >= 0) {
      return "#deb616";
    } else if (minutesDiff < 300 && minutesDiff >= 0) {
      return "green";
    } else if (minutesDiff >= 300) {
      return "#1ccb9e";
    } else {
      return "#241f1f";
    }
  };

  const handleNavigate = (date) => {
    setCurrentMonth(date.getMonth() + 1);
    setCurrentYear(date.getFullYear());
    getTimes(date.getMonth() + 1, date.getFullYear());
  };

  const onSelectSlot = useCallback((slotInfo) => {
    const { start, clientX, clientY } = slotInfo;
    setStartDate(start)
    const today = new Date();
    console.log(slotInfo);

      setSelectedDate(start);
      setOpenDialog(true)

  }, []);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleShowDialog = () => {
    setOpenDialog(true);
    handleCloseMenu();
  };

  const handleShowContent = () => {
    setShowContent(true);
    handleCloseMenu();
  };

  const handleCloseDialog = () =>{
    setOpenDialog(false)
  }

  const dayPropGetter = (date) => {
    const today = new Date();
    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      return {
        style: {
          backgroundColor: '#63cfab',
          cursor: 'pointer',
          color: 'white',
        },
      };
    }
    return {};
  };

  const defaultDate = useMemo(() => new Date(), []);

  // File drop handler
  const allowedFileTypes = [
    'image/jpeg', 'image/png', 'application/pdf', 'application/zip', 'audio/mpeg', 'audio/wav', 'video/mp4', 'video/x-matroska',
  ];

  const handleFileDrop = (droppedFiles) => {
    const validFiles = droppedFiles.filter(file =>
      allowedFileTypes.includes(file.type)
    );

    if (validFiles.length > 0) {
      setFile(validFiles[0]); // Only accept the first valid file
    } else {
      alert('Some files are not valid types and will not be uploaded.');
    }
  };
  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleSubmitFeedback = async () => {
    setLoading(true)
    const data = {
       feedback:feedback,
       id:selected
    }
    const res =await handleGiveFeedback.mutateAsync(data)

    console.log(res)
    setLoading(false)
    refetch()

  }
  const handleSubmit = async () => {
    if (!description || !file) {
      alert('Please fill the description and select a file to upload');
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('description', description);
    formData.append('date', selectedDate);
    formData.append('file', file);

    try {
      const token = localStorage.getItem('authToken');
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      await axios.post(`${server_url}/emplyoee/daily-work/create`, formData, config);

      alert('File uploaded successfully!');
      setOpenDialog(false);
      setDescription('');
      setFile(null);
    } catch (error) {
      alert('Failed to upload file');
    } finally {
      setLoading(false);
    }
  };

  const [{ isOver }, drop] = useDrop({
    accept: 'file',
    drop: (item) => handleFileDrop(item.files),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <>
    <Grid position={ 'relative'}> 
      <Calendar
        localizer={localizer}
        events={events}
        eventPropGetter={(event) => ({
          style: {
            backgroundColor: event.color,
            color: 'white',
          },
        })}
        defaultDate={defaultDate}
        startAccessor="start"
        endAccessor="end"
        onNavigate={handleNavigate}
        dayPropGetter={dayPropGetter}
        onSelectSlot={onSelectSlot}
        selectable={true}
        style={{
          height: size.height,
          width: size.width,
          color: 'grey',
          fontFamily: 'Poppins, sans-serif',
          fontWeight: '200',
          fontSize: '14px',
          border: 'none',
        }}
      />

    
      {/* Dialog Component */}
      <Dialog open={openDialog} onClose={handleCloseDialog} PaperProps={{ sx: { minWidth: 900, borderRadius: 5, boxShadow: 24, padding: 2, height:'60vh', overflow:'auto' } }}>
          <DialogContent>
            {/* Tabs for Add Work / Show Work */}
            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="tabs" centered>
              <Tab label="Add Work" />
              <Tab label="Show Work" />
            </Tabs>

            {/* Add Work Tab */}
            {selectedTab === 0 && (
              <Box>
                <TextField
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  fullWidth
                  multiline
                  
                  margin="normal"
                />

                {/* Drag and Drop for file */}
                <Box
                  ref={drop}
                  sx={{
                    border: '2px dashed grey',
                    padding: 2,
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <Typography>Drag & Drop a File Here</Typography>
                  <input
    type="file"
    name="file"
    style={{
      opacity: 0, // Hide the input element
      width: '100%',
      height: '100%',
    }}
    onChange={(e) => handleFileDrop([e.target.files[0]])}
    id="file-upload"
  />
  
  {/* Visible Button to Trigger File Input */}
  <Button
    variant="contained"
    component="label"
    htmlFor="file-upload"
    sx={{
      marginTop: 2,
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    }}
  >Upload File</Button>
                </Box>
               
                {/* Display selected file */}
                <Box>
                  {file && <Typography>{file.name}</Typography>}
                </Box>
              </Box>
            )}

            {/* Show Work Tab */}
            {selectedTab === 1 && (
  <Grid >
    {dailyWork.length > 0 ? (
      dailyWork.map((work, workIndex) => (
        <Box key={workIndex}>
          <Grid
  sx={{
    display: 'flex',
    flexWrap: 'wrap',
    gap: 2,
    marginTop: 2,
    width: '100%',
    alignContent: 'center',
    justifyContent: 'space-between',
    backgroundColor: useTheme().palette.background.default,
    padding: 2,
    fontSize: "16px",
    textAlign: "left",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    position: "sticky",
    top: 0,
    zIndex: 1,
    transition: 'transform 0.3s ease, color 0.3s ease, box-shadow 0.3s ease', // Smooth transition for all effects
    borderRadius:5,
    
    '&:hover': {
      color: '#004d40', // Darker shade of teal on hover
      transform: 'scale(1.05)', // Slight zoom effect on hover
      boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)', // Increase shadow size for a floating effect
    },

    '&:active': {
      transform: 'scale(1.02)', // A slight zoom in when clicked (active state)
    },
  }}
>
          
            <Box>
              <Typography >{work.description}</Typography>
              <Typography >Date: {new Date(work.date).toLocaleDateString()}</Typography>
              <Typography sx={{
                fontSize:'16px'
              }}>Feedbacks :</Typography>
              {work?.feedBack && work?.feedBack?.map((item, index) => {
  return (
    <Typography key={index} fontSize={'12px'} ml={2}>
        {item?.feedback}  ~  {item?.feedbackGiverName || 'Imperial Milesstones'}
    </Typography>
  );
})}
            </Box>

          <Box sx={{
            display:'flex',
            alignItem:'center',
            gap:2
          }} >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                cursor: 'pointer',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.05) rotateY(10deg)',
                  boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
                },
              }}
              onClick={(e) => handlePopoverOpen(e, work.file)} // Ensure this function is defined to handle the popover state
            >
      <Link to={`${work.file}`} target="_blank" rel="noopener noreferrer">
  <GetFileThumbnail fileType={work.fileType} fileUrl={work.file} />
</Link></Box>
              <IconButton color="primary" onClick={(e) => handlePopoverOpen(e, work.file, work?._id)}>
                <FeedbackIcon />
              </IconButton>
            </Box>
          </Grid>

          {/* Feedback Section */}
          <Popover
            id={id} // Ensure `id` is dynamically generated if necessary
            open={open} // Make sure open state is managed properly
            anchorEl={anchorEl} // Anchor element for popover
            onClose={handlePopoverClose} // Function to close popover
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
          
              <Box sx={{ margin: 2 }}>
                <TextField
                  label="Add Feedback"
                  value={feedback} // Feedback state value
                  onChange={handleFeedbackChange} // Ensure this handles state change properly
                  fullWidth
                  multiline
                  margin="normal"
                />
                <Button
                  onClick={handleSubmitFeedback} // Ensure this submits feedback properly
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled={loading} // Loading state to disable the button while submitting
                  sx={{ marginTop: 2 }}
                >
                  {loading ? <CircularProgress size={24} /> : 'Submit Feedback'}
                </Button>
              </Box>
      
          </Popover>
        </Box>
      ))
    ) : (
      <Typography>No work data found for this date.</Typography>
    )}
  </Grid>
)}


          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">Close</Button>
            {selectedTab === 0 && (
              <Button onClick={handleSubmit} color="primary" disabled={loading || (startDate && (startDate.getDate() !== today.getDate() || startDate.getMonth() !== today.getMonth() || startDate.getFullYear() !== today.getFullYear()))}>
                {loading ? <CircularProgress size={24} /> : 'Submit'}
              </Button>
            )}
          </DialogActions>
        </Dialog>

      </Grid>
    </>
  );
}

function CalendarView({size, getTimes}) {
  return (
    <DndProvider backend={HTML5Backend}>
      <CalendarActions size={size} getTimes={getTimes}/>
    </DndProvider>
  );
}

export default CalendarView;
