import { useTheme } from "@emotion/react";
import { border, flexbox, height } from "@mui/system";
import { color } from "framer-motion";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";

function PuchInData({ employees, leaves, onDateChange }) {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Default to current month
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Default to current year
  const theme = useTheme()
  // Donut Chart Data
  const donutChartData = {
    series: [
      leaves.filter(leave => leave.status === "Approved").length, 
      leaves.filter(leave => leave.status === "Pending").length,
      leaves.filter(leave => leave.status === "Rejected").length
    ],
    options: {
      chart: {
        type: "donut",
        height: 250,
      },
      tooltip: {
        enabled: true,
        followCursor: true,
        offsetX: 10,
        theme: 'dark',
        y: {
          formatter: (val, { seriesIndex }) => {
            switch (seriesIndex) {
              case 0:
                return `${val} Approved`;
              case 1:
                return `${val} Pending`;
              case 2:
                return `${val} Rejected`;
              default:
                return `${val}`;
            }
          },
        },
      },
      stroke: {
        width: 3,
        colors: ['grey'],
      },
      legend: {
        show: true, position: 'bottom', horizontalAlign: 'left',
        labels: { colors: theme.palette.text.primary, fontSize: '14px', fontFamily: 'Arial, sans-serif' },
        markers: { width: 10, height: 10, radius: 0, offsetX: -5 },
      },
      dataLabels: {
        markers: {
          width: 10,
          height: 10,
          radius: 0,
        },
      },
      labels: ["Approved", "Pending", "Rejected"], // Updated labels
      plotOptions: {
        pie: {
          donut: {
            size: "10%",
          },
        },
      },
      colors: ["#00E396", "#FF4560", "#FF914D"], // Added a color for "Rejected"
      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: {
              height: 250,
            },
          },
        },
      ],
    },
  };

  // Handle month/year selection change


  return (
    <div style={styles.dashboardContainer}>
    <div style={styles.chartContainer}>
      {/* Date Selection */}
      <div style={styles.dateSelection}>
        <select
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(Number(e.target.value))}
          style={styles.select}
        >
          {Array.from({ length: 12 }, (_, i) => (
            <option key={i} value={i + 1}>{`Month ${i + 1}`}</option>
          ))}
        </select>
        <select
          value={selectedYear}
          onChange={(e) => setSelectedYear(Number(e.target.value))}
          style={styles.select}
        >
          {Array.from({ length: 10 }, (_, i) => (
            <option key={i} value={2020 + i}>{2020 + i}</option>
          ))}
        </select>
        
      </div>

      {/* 3D Donut Chart */}
      <div id="donutChart" style={styles.chartWrapper}>
        <ReactApexChart
          options={donutChartData.options}
          series={donutChartData.series}
          type="donut"
          height={250}
        />
      </div>
    </div>
    </div>
  );
}

// Styles for the component
const styles = {
    dashboardContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        height: "100vh",
        fontFamily: "'Arial', sans-serif",
      },
  chartContainer: {
    justifyContent: "center",
    width: "100%",
    maxWidth: "800px",
    padding: "35px",
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.6)",
  },
  chartWrapper: {
    width: "100%",
  },
  dateSelection: {
    display: "flex",
    justifyContent: "right",
    alignItems: "top",
    marginBottom: "50px",
  },
  select: {
    marginRight: "10px",
    padding: "8px 14px",
    fontSize: "14px",
    borderRadius: "5px",
    outLine : "none",
    border: "none",
    backgroundColor: 'transparent',
  },
  button: {
    padding: "8px 15px",
    backgroundColor: "#00E396",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default PuchInData;
