import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";

function HRMDashboard({ employeesData, employees }) {
  const [employeeStatus, setEmployeeStatus] = useState({
    latePunchedInCount: [],
    onTimePunchedInCount: [],
    punchedIn: [],
    punchedOut: [],
    absent: []
  });

  const [employeeList, setEmployeeList] = useState(employees?.data?.message[0] || []);
 
  const theme = useTheme();

  useEffect(() => {
    const employeeArray = Array.isArray(employeesData) ? employeesData : Object.values(employeesData);

    if (employeeArray.length > 0) {
      employeeArray.forEach(employee => {
        const today = DateTime.now();
        const workingStartTime = employee?.workingHours?.start || "10:00";
        const workingStartDate = today.set({
          hour: parseInt(workingStartTime.split(":")[0]),
          minute: parseInt(workingStartTime.split(":")[1]),
          second: 0,
          millisecond: 0,
        });
        const todayString = today.toISODate();
        const emp = employeeList?.find((emp) => emp?._id === employee?.employeeId);

        const absentEmp = employeeArray?.filter((emp) => {
          const recordForToday = emp?.records?.find((record) => record?.day === todayString);
          return !recordForToday;
        });

        absentEmp.forEach((empo) => {
          const filteredEmp = employeeList?.find((emp) => emp?._id === empo?.employeeId);
          
          if (filteredEmp) {
            setEmployeeStatus(prevState => {
              const isAlreadyAbsent = prevState.absent.some(empInList => empInList?._id === filteredEmp?._id);
              if (!isAlreadyAbsent) {
                return {
                  ...prevState,
                  absent: [...prevState.absent, filteredEmp],
                };
              }
              return prevState;
            });
          }
        });

        employee?.records?.forEach(record => {
          const recordDate = DateTime.fromISO(record?.firstPunchIn);
          if (record?.day === todayString) {
            if (recordDate > workingStartDate) {
              if (!employeeStatus.latePunchedInCount.some(empInList => empInList?._id === emp?._id)) {
                setEmployeeStatus(prevState => ({
                  ...prevState,
                  latePunchedInCount: [...prevState.latePunchedInCount, emp],
                }));
              }
            } else {
              if (!employeeStatus.onTimePunchedInCount.some(empInList => empInList?._id === emp?._id)) {
                setEmployeeStatus(prevState => ({
                  ...prevState,
                  onTimePunchedInCount: [...prevState.onTimePunchedInCount, emp],
                }));
              }
            }

            if (recordDate && record?.lastPunchIn === null) {
              if (!employeeStatus.punchedIn.some(empInList => empInList?._id === emp?._id)) {
                setEmployeeStatus(prevState => ({
                  ...prevState,
                  punchedIn: [...prevState.punchedIn, emp],
                }));
              }
            } else {
              if (!employeeStatus.punchedOut.some(empInList => empInList?._id === emp?._id)) {
                setEmployeeStatus(prevState => ({
                  ...prevState,
                  punchedOut: [...prevState.punchedOut, emp],
                }));
              }
            }
          }
        });
      });
    }
  }, [employeesData]);

  const chartData = {
    series: [
      employeeStatus.latePunchedInCount.length,
      employeeStatus.onTimePunchedInCount.length,
      employeeStatus.punchedIn.length,
      employeeStatus.punchedOut.length,
      employeeStatus.absent.length
    ],
    options: {
      chart: {
        type: 'donut',
        toolbar: { show: false },
        animations: { enabled: true, easing: 'easeinout', speed: 800, animateGradients: true },
      },
      labels: ['Late Punch In', 'On Time Punch In', 'Punched In', 'Punched Out', 'Absent'],
      plotOptions: {
        pie: {
          donut: { size: '10%', background: 'transparent' },
        },
      },
      dataLabels: { enabled: false },
      colors: ["#FF8560", "#00E796", "#00F396", "#cc716a", "#FF2560"],
      stroke: {
        width: 3,
        colors: [
          "#FFB59A", "#4EE4B2", "#4EE4B2", "#cc716a", "#FF6C91"
        ]
      },
      legend: {
        show: true, position: 'bottom', horizontalAlign: 'left',
        labels: { colors: theme.palette.text.primary, fontSize: '14px', fontFamily: 'Arial, sans-serif' },
        markers: { width: 10, height: 10, radius: 0, offsetX: -5 },
      },
      tooltip: {
        enabled: true,
        theme: "dark",
        y: {
          formatter: (val, { seriesIndex }) => {
            let employeeListForTooltip = [];

            switch (seriesIndex) {
              case 0: employeeListForTooltip = employeeStatus.latePunchedInCount; break;
              case 1: employeeListForTooltip = employeeStatus.onTimePunchedInCount; break;
              case 2: employeeListForTooltip = employeeStatus.punchedIn; break;
              case 3: employeeListForTooltip = employeeStatus.punchedOut; break;
              case 4: employeeListForTooltip = employeeStatus.absent; break;
              default: break;
            }

            const maxNamesToShow = 5;
            const displayedNames = employeeListForTooltip.slice(0, maxNamesToShow).map(emp => emp?.name || "Unknown").join("<br />");
            const remainingCount = employeeListForTooltip.length - maxNamesToShow;
            const remainingMessage = remainingCount > 0 ? `<br /><em>and ${remainingCount} more...</em>` : "";

            return `${val} employees:<br />${displayedNames}${remainingMessage}`;
          },
        },
      },
    },
  };

  // Function to close the selected list


  return (
    <div style={styles.dashboardContainer}>
      <div style={styles.chartContainer}>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="donut"
          height={350}
        />
      </div>

     
    </div>
  );
}

const styles = {
  dashboardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    height: "100vh",
    fontFamily: "'Arial', sans-serif",
  },
  chartContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    maxWidth: "800px",
    padding: "30px",
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.6)",
  },
  employeeList: {
    marginTop: "20px",
    padding: "20px",
    backgroundColor: "#f4f4f4",
    borderRadius: "10px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
    width: "300px",
    maxHeight: "300px",
    overflowY: "auto",
    transform: "scale(1.05)",
    transition: "transform 0.3s ease",
  },
  listHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  closeButton: {
    background: "transparent",
    border: "none",
    fontSize: "20px",
    cursor: "pointer",
    color: "#FF2560",
    fontWeight: "bold",
  },
};

export default HRMDashboard;
