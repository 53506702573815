import { Add, Cancel } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Autocomplete,
  Button,
  Popover,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import teamImage from "../../../assets/images/team.jpg";
import { toast } from "react-toastify";
import Loading from "../../../assets/Loading/Index";
import { useGet, usePost } from "../../../helpers/axios/useApi";
import { useAuth } from "../../../middlewares/auth";
import { useSpring, animated } from 'react-spring';
import TeamPopover from "./teamDetails";
import GlassEffect from "../../../theme/glassEffect";
import { useTheme } from "@emotion/react";
import TeamTreeChart from "./TeamTree";
import TeamSection from "./teams";
function Departments({ departments=[], employees=[] }) {
  const [activeIndex, setActiveIndex] = useState();
  const [selectedTeams, setSelectedTeams] = useState();
  const [teams, setTeams] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedTeamLead, setSelectedTeamLead] = useState([]);
  const [openNewMemberAdder, setOpenNewMemberAdder] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teamName, setTeamName] = useState("");
  const [teamView , setTeamView] = useState(true)
  const [isHovered, setIsHovered] = useState(null);
  const { mutateAsync: createTeam, isLoading: isCreatingTeam } = usePost(
    "department/team-create"
  );
  const {
    data: teamsData,
    isLoading: isLoadingTeams,
    error: teamsError,
    refetch,
  } = useGet(
    `department/team-get/`,
    { departmentId: activeIndex },
    {
      enabled: !!activeIndex, // Fetch teams only if activeIndex is available
    }
  );
  const {user} = useAuth()
  const {mutateAsync:addNewMember, isLoading: isAddingNewMember} = usePost(`department/team-add-member`);


  useEffect(() => {
    if (teamsData) {
      setTeams(teamsData?.data?.message[0]);
      setTeamView(true)
    }
  }, [teamsData,activeIndex ]);
  const handleItemClick = (id) => {
    setActiveIndex(id);
    setTeamView(false)

  };

  

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedTeam(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'team-popover' : undefined;



  
  useEffect(() => {
    if (departments?.length > 0) {
      const initialIndex = departments[0]._id;
      setActiveIndex(initialIndex);
    }
  }, [departments]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  
   const handleOpenNewMemberAdder = (id) => {
    setOpenNewMemberAdder(true);
    setSelectedTeams(id);
   }
  const handleCloseNewMemberAdder = () => {
    setOpenNewMemberAdder(false);
    setSelectedTeams(null);
    setSelectedMembers([]);
    setMembers([]);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedMembers([]);
    setSelectedTeamLead(null);
    setTeamName("");
    setMembers([]);
  };

  const handleAddTeam = async () => {
    if (!teamName || !selectedMembers || !selectedTeamLead) {
      toast.error("All fields are required");
      return;
    }

    const teamDetails = {
      teamName,
      teamLead: selectedTeamLead,
      teamMembers: selectedMembers,
      departmentId: activeIndex,
    };
    const data = await createTeam({ teamDetails });
    if (data.data) {
      toast.success(data.data.message);
      refetch();
      handleCloseModal();
    } else {
      toast.error(data.error.error);
    }
  };
  const handleAddMember = async() => {
    if(selectedMembers?.length === 0){
      toast.error("Please select members");
      return;
    }
    const members  = {
       teamId: selectedTeams,
       teamMembers: selectedMembers
    }
    const result  = await addNewMember({members})
    if(result.data){
      toast.success(result.data.message);
      refetch();
      handleCloseNewMemberAdder();
    }else{
      toast.error(result.error.error);
    }
  };

  const emplyoeeList = () => {
    // If no team is selected, we only filter based on user role
    if (!selectedTeams) {
      return employees
        ?.filter((employee) => {
          // Check user roles
          if (user?.role === 'superAdmin' || user?.role === 'admin') {
            // If user is superAdmin or admin, include all employees
            return true;
          } else if (user?.junior) {
            // If user is a junior, include only those in the junior array
            return user.junior.includes(employee._id);
          }
          // Default case: exclude all
          return false;
        })
        .map((employee) => ({
          label: `${employee.name} (${employee.position})`,
          _id: employee._id,
        })) || [];
    }
 
    // If a team is selected
    const team = teams.find((tm) => tm._id === selectedTeams);
  
    if (!team) {
      return []; // Return an empty array if no team is found
    }
  
    const alreadyMember = [...team?.teamMembers, team?.teamLead]; // Combine existing members and team lead
  
    const filteredEmployees = employees
      ?.filter((employee) => {
        const isAlreadyMember = alreadyMember.includes(employee._id);
        
        // Check user roles
        if (user?.role === 'superAdmin' || user?.role === 'admin') {
          // If user is superAdmin or admin, include all except already members
          return !isAlreadyMember;
        } else if (user?.junior) {
          // If user is a junior, include only those in the junior array
          return user.junior.includes(employee._id) && !isAlreadyMember;
        }
  
        // Default case: include only if not already a member
        return !isAlreadyMember;
      })
      .map((employee) => ({
        label: `${employee.name} (${employee.position})`,
        _id: employee._id,
      })) || [];
  
    return filteredEmployees; // Return the filtered list of employees
  };
  
  const scrollY = React.useRef(0);

  const handleScroll = (e) => {
    scrollY.current = e.target.scrollTop;
  };
  const handleMouseEnter = (id) => {
    setIsHovered(id);
  };

  const handleMouseLeave = (id) => {
    setIsHovered(null);
  };
  const getSpringStyle = (index) => {
    const parallaxEffect = (scrollY.current - index * 50) / 20; // Adjust based on item height
    return useSpring({
      transform: `translateZ(${parallaxEffect}px)`,
      config: { tension: 200, friction: 15 },
    });
  };
  return (
    <Box width={"100%"} display={"flex"} gap={2}>
      {/* Departments Sidebar */}
     
      <Grid
        sx={{
          width: "25%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          borderRadius: "15px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          overflowY: "auto",
        }}
      >
         <GlassEffect.GlassContainer>
         <Grid
        sx={{
          height: "72.5vh",
          display: "flex",
          flexDirection: "column",
          borderRadius: "15px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          overflowY: "auto",
        }}
      >
        <Typography
          sx={{
            fontSize: "22px",
            borderBottom: "3px solid #dbe3e8",
            padding: "1rem",
            textAlign: "left",
            fontWeight: 600,
            color: useTheme().palette.text.primary,
          }}
        >
          Departments ({departments?.length})
        </Typography>
        {departments?.map((item) => (
          <Box
            key={item._id}
            onClick={() => handleItemClick(item._id)}
             
            sx={{
              position: "relative",
              margin: "0.3rem",
              borderRadius: "8px",
              backgroundColor: activeIndex === item._id ? "#e4edf5" : "#ffffff",
              transition: "background-color 0.3s ease",
             
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#f0f4f8",
              },
              "&:after": {
                content: '""',
                position: "absolute",
                left: 0,
                bottom: 0,
                width: activeIndex === item._id ? "100%" : 0,
                height: "4px",
                backgroundColor: "#007bff",
                transition: "width 0.3s ease",
              },
            }}
          >
            <Grid
            onMouseEnter={()=>handleMouseEnter(item._id)}
            onMouseLeave={()=>handleMouseLeave(item._id)}
              sx={{
                padding: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                transform: isHovered === item._id ? "scale(1.08)" : "scale(1)",
                transition: "all 0.3s ease-in-out",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "1rem",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {item?.departmentName}
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  fontSize: "10px",
                  width: "fit-content",
                  textAlign: "left",
                  transition: "padding 0.3s ease",
                  marginTop: "3rem",
                  border: "1px solid grey",
                  padding: "0.2rem",
                  borderRadius: "5px",
                }}
              >
                {employees?.find((emp) => emp._id === item?.departmentHead)
                  ?.name || "Unknown"}
              </Typography>
            </Grid>
          </Box>
        ))}
        </Grid>
         </GlassEffect.GlassContainer>
      </Grid>
     

      {/* Teams Section */}
      <Box
        sx={{
          width: "73%",
          height: "100vh",
          borderRadius: "15px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          overflowY: "auto",
        }}
      >
        <GlassEffect.GlassContainer>
           {teamView && <TeamSection teams={teams} employees={employees} isLoadingTeams={isLoadingTeams} handleOpenModal={handleOpenModal} handleOpenNewMemberAdder={handleOpenNewMemberAdder}/>}
        </GlassEffect.GlassContainer>
      </Box>

      {/* Add Team Modal */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        PaperProps={{
          sx: {
            borderRadius: "25px",
            padding: "1rem",
          },
        }}
      >
        {isCreatingTeam && <Loading />}
        <DialogTitle>Add New Team</DialogTitle>
        {/* <Avatar
          src={teamImage}
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: "0",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            mixBlendMode: "normal",
            "& img": {
              filter: "blur(10px)",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            },
          }}
        /> */}
        <DialogContent>
          <Box
            sx={{
              width: "500px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 2,
            }}
          >
            <TextField
              name="teamName"
              label="Team Name"
              fullWidth
              value={teamName}
              onChange={(event) => setTeamName(event.target.value)}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "#000",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#ced1d6",
                    borderRadius: "15px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    border: "none",
                  },
                  "&:hover fieldset": {
                    borderColor: "#398bf7",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#398bf7",
                  },
                },
              }}
            />
            <Autocomplete
              multiple
              options={employees?.map((employee) => ({
                label: `${employee.name} (${employee?.position})`,
                _id: employee._id,
              }))}
              getOptionLabel={(option) => option.label || ""}
              value={members}
              onChange={(_, newValue) => {
                setMembers(newValue);
                setSelectedMembers(newValue?.map((item) => item._id));
              }}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Members"
                  variant="outlined"
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#000",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ced1d6",
                        borderRadius: "15px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        border: "none",
                      },
                      "&:hover fieldset": {
                        borderColor: "#398bf7",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#398bf7",
                      },
                    },
                  }}
                />
              )}
            />

            <Autocomplete
              options={employees}
              getOptionLabel={(option) =>
                `${option.name} ${option.email} (${option.position})`
              }
              value={
                employees?.find((option) => option._id === selectedTeamLead) ||
                null
              }
              onChange={(event, newValue) => {
                setSelectedTeamLead(newValue?._id || null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Team Leader"
                  variant="outlined"
                  fullWidth
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#000",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ced1d6",
                        borderRadius: "15px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        border: "none",
                      },
                      "&:hover fieldset": {
                        borderColor: "#398bf7",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#398bf7",
                      },
                    },
                  }}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={handleCloseModal}
            sx={{
              borderRadius: "15px",
              padding: "8px 16px",
              display: "flex",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#d32f2f",
              },
            }}
          >
            <Cancel sx={{ color: "white", fontSize: "20px" }} />
            <Typography sx={{ ml: 1, color: "white", fontWeight: "500" }}>
              Cancel
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddTeam}
            sx={{
              borderRadius: "15px",
              padding: "8px 16px",
              display: "flex",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#357ae8",
              },
            }}
          >
            <Add sx={{ color: "white", fontSize: "20px" }} />
            <Typography sx={{ ml: 1, color: "white", fontWeight: "500" }}>
              Add
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openNewMemberAdder}
        onClose={handleCloseNewMemberAdder}
        PaperProps={{
          sx: {
            borderRadius: "25px",
            padding: "1rem",
          },
        }}
      >
        {isAddingNewMember && <Loading />}
        <DialogTitle>Add New Members</DialogTitle>
        {/* <Avatar
          src={teamImage}
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: "0",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            mixBlendMode: "normal",
            "& img": {
              filter: "blur(10px)",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            },
          }}
        /> */}
        <DialogContent>
          <Box
            sx={{
              width: "500px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 2,
            }}
          >
            <Autocomplete
              multiple
              options={emplyoeeList()}
              getOptionLabel={(option) => option.label || ""}
              value={members}
              onChange={(_, newValue) => {
                setMembers(newValue);
                setSelectedMembers(newValue?.map((item) => item._id));
              }}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Members"
                  variant="outlined"
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#000",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ced1d6",
                        borderRadius: "15px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        border: "none",
                      },
                      "&:hover fieldset": {
                        borderColor: "#398bf7",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#398bf7",
                      },
                    },
                  }}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={handleCloseNewMemberAdder}
            sx={{
              borderRadius: "15px",
              padding: "8px 16px",
              display: "flex",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#d32f2f",
              },
            }}
          >
            <Cancel sx={{ color: "white", fontSize: "20px" }} />
            <Typography sx={{ ml: 1, color: "white", fontWeight: "500" }}>
              Cancel
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddMember}
            sx={{
              borderRadius: "15px",
              padding: "8px 16px",
              display: "flex",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#357ae8",
              },
            }}
          >
            <Add sx={{ color: "white", fontSize: "20px" }} />
            <Typography sx={{ ml: 1, color: "white", fontWeight: "500" }}>
              Add
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <TeamPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        selectedTeam={selectedTeam}
        employees={employees}
      />

    </Box>
  );
}

export default Departments;
