import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import PuchInData from "./PuchedInData";
import { Grid } from "@mui/system";
import { useGet } from "../../../helpers/axios/useApi";
import { DateTime } from "luxon";
import HRMDashboard from "./HRMDashboard";
import GlassEffect from "../../../theme/glassEffect";
import { Paper, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
function DashBoard({ employees }) {
  const [employeesData, setEmployeeData] = useState({}); // Employee attendance data
  const [selectedMonth, setSelectedMonth] = useState(currentMonth); // Default to October
  const [selectedYear, setSelectedYear] = useState(currentYear); // Default to 2024
  const [employeeIds, setEmployeeIds] = useState([]);
  const [leaves, setLeave] = useState([]);
  const { data: leave, refetch } = useGet(
    "employee/leave/get-all-by-month",
    {
      month: selectedMonth + 1,
      year: selectedYear,
    },
    {},
    { queryKey: "leave" }
  );

  useEffect(() => {
    if (leave?.data?.data) {
      setLeave(leave?.data?.data);
    }
  }, [leaves]);

  // API Call to fetch daily records and policy data
  const { data: dailyRecords, error: recordsError } = useGet(
    "employee/work-tracking/daily-records-multipal",
    {
      userId: employeeIds?.join(","),
      startDate: DateTime.fromObject({
        year: selectedYear,
        month: selectedMonth + 1,
        day: 1,
      }).toISODate(),
      endDate: DateTime.fromObject({
        year: selectedYear,
        month: selectedMonth + 1,
      })
        .endOf("month")
        .toISODate(),
    },
    { enabled: employeeIds?.length > 0 }, // Only make the call when employeeIds is not empty
    { queryKey: "dailyRecords" }
  );

  const { data: policyData, error: policyError } = useGet(
    "company/policy/attendece-get",
    { employeeId: employeeIds?.join(",") },
    { enabled: employeeIds?.length > 0 },
    { queryKey: "policyData" }
  );

  // Deep merge function for handling nested objects and arrays
  const deepMerge = (target, source) => {
    if (
      target &&
      source &&
      typeof target === "object" &&
      typeof source === "object"
    ) {
      Object.keys(source).forEach((key) => {
        if (Array.isArray(target[key]) && Array.isArray(source[key])) {
          target[key] = [...target[key], ...source[key]];
        } else if (
          typeof target[key] === "object" &&
          typeof source[key] === "object"
        ) {
          target[key] = deepMerge({ ...target[key] }, source[key]);
        } else {
          target[key] = source[key];
        }
      });
    }
    return target;
  };

  const fetchEmployeeData = async () => {
    if (recordsError || policyError) {
      return;
    }

    const employeeData = policyData?.data?.data || [];
    const attendanceData = dailyRecords?.data?.data?.employees || [];

    // Merge employee data with attendance data
    const mergedData = employeeData.map((employee) => {
      const attendance = attendanceData.find(
        (att) => att.userId === employee.employeeId
      );

      // Merge the employee data with the attendance (currentStatus and records)
      const mergedEmployeeData = deepMerge(
        { ...employee },
        {
          currentStatus: attendance?.currentStatus || {
            isPunchedIn: false,
            punchIn: null,
          },
          records: attendance?.records || [], // Merge the attendance records
          workingDays: attendance?.workingDays || {}, // Merge workingDays (0-6)
        }
      );

      return mergedEmployeeData;
    });
    // Set merged data into state
    setEmployeeData(mergedData);
  };
  // Fetch data for the current page's employee IDs
  useEffect(() => {
    if (employeeIds?.length > 0) {
      fetchEmployeeData(); // Fetch employee data if employee IDs are available
    }
  }, [employeeIds, dailyRecords, policyData]);

  // Update employeeIds based on the current page and rowsPerPage
  useEffect(() => {
    const newEmployeeIds = employees?.data?.message[0]?.map(
      (employee) => employee._id
    );
    setEmployeeIds(newEmployeeIds); // Set employee IDs to fetch data for the current page
  }, [employees]);



  const Holiday = [
    { id: 1, date: "Nov 8, 2024", day: "Friday", name: "Diwali" },
    { id: 2, date: "Dec 25, 2024", day: "Wednesday", name: "Christmas" },
  ];
  

  return (
    <>
      <Grid height={"80vh"} width={"100%"}>
        <Grid  sx={{  width:"100%", display:"flex", gap:"1rem"}}>
          <Grid width={"30%"}>
            <HRMDashboard employeesData={employeesData} employees={employees} />
          </Grid>
          <Grid width={"30%"}>
            <PuchInData employees={employees} leaves={leaves} />
          </Grid>
          <Grid width={"37%"} mt={2}>
    <GlassEffect.GlassContainer>
   <Paper
  sx={{
    backgroundColor: "transparent",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    marginTop: 0,
    borderRadius: 2.5,
    overflow:'hidden',
    height: '76vh',
    
  }}
>
  <Typography
    variant="h6"
    sx={{
      mt: -2,
      fontWeight: "bold",
      color: "#00796b", // Dark teal color
      padding: 1,
      fontSize: "16px",
      borderRadius: 3.5,
      textAlign: "left",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.4)",
      position: "sticky",
      top: 0,
      zIndex: 1,
      transition: 'color 0.3s ease', // Smooth color transition
      
    }}
  >
    Upcoming Events
  </Typography>
  <Grid container direction="column" spacing={2} sx={{ marginTop: 3, padding: 2 }}>
    {Holiday.map((holiday) => (
      <Grid item key={holiday.id}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{
            padding: 2,
            borderRadius: 2,
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow effect
            transition: 'all 0.3s ease-in-out', // Smooth transition for hover effects
            '&:hover': {
              boxShadow: '0 10px 20px rgba(0, 0, 0, 0.15)', // Enhanced shadow on hover
              transform: 'translateY(-4px)', // Lifting effect
              background: 'linear-gradient(135deg, #f1b6c2 0%, #d169b2 50%, #a3d1e5 100%)',

              cursor: 'pointer', // Pointer cursor on hover for interactivity
            },
          }}
        >
          <Grid item>
            <Typography variant="body2" color="text.primary">
              {holiday.date}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {holiday.day}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="text.primary">
              {holiday.name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    ))}
  </Grid>
</Paper>
</GlassEffect.GlassContainer>
</Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default DashBoard;
