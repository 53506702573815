import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EmpDetails from "../../components/emplyoee/empDetails";
import { useParams } from "react-router-dom";
import { useGet } from "../../helpers/axios/useApi";
import ProfileNav from "../../components/user/profiveNav";
import CalenderView from "../../components/emplyoee/calenderView";
import EmplyoeeDetailTab from "../../components/emplyoee/tabDetails";
import DailyRecordsTable from "../../components/emplyoee/attendence/tableView";
import CalendarViewAttendence from "../../components/emplyoee/attendence/calenderView";
import Payrolle from "../../components/emplyoee/payrolle";
import Leave from "../../components/emplyoee/leave/Leave";
import { useTheme } from "@emotion/react";
import GlassEffect from "../../theme/glassEffect";
import WorkHistory from "./WorkHistory";
import { useAuth } from "../../middlewares/auth";

function EmplyoeeDetails() {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("Basic Details");
  const {user} = useAuth()
  const tabs = [
    "Basic Details",
    "Attendance",
    "Leave",
    "Payroll",
    "Work History",
  ];


  const handleTabChange = (tab) => {
    // Check if the tab is 'Work History'
    if (tab === 'Work History') {
        // Check if the user's role is 'admin', 'superAdmin', or 'manager'
        if (['admin', 'superAdmin', 'manager'].includes(user?.role)) {
            // Allow tab change if role is valid
            setActiveTab(tab);
        } else {
            // Optionally, you can show a message or log an error when access is denied
            console.log('You do not have permission to view Work History.');
            // Optionally, show a user-friendly message
            // alert("You do not have permission to access this tab.");
        }
    } else {
        // Allow switching to other tabs
        setActiveTab(tab);
    }
};


  const renderContent = () => {
    switch (activeTab) {
      case "Basic Details":
        return (
          
          <Grid  width="100%" gap="1rem" display={"flex"}>
            
            <Grid item width="48%">
            <GlassEffect.GlassContainer>
              <EmpDetails />
              </GlassEffect.GlassContainer>
            </Grid>
            
            <Grid  width="52%" sx={{  borderRadius: "10px", padding: "0.3rem" }}>
            <GlassEffect.GlassContainer>
              <Grid display={"flex"} direction="column" width="100%"  padding={"0.5rem"}>
               
                <Grid width={"100%"} sx={{backgroundColor: "white",  borderRadius: "10px", padding: "1rem" }}>
                  <CalenderView
                    getTimes={(month, year) => console.log(month, year)}
                    size={{ height: 300, width: "100%" }}
                  />
                </Grid>
                  
                <Grid item maxWidth="77vh"  width="100%" sx={{  borderRadius: "10px", mt: "1rem", padding: "0.2rem" }}>
                  <EmplyoeeDetailTab />
                </Grid>
              </Grid>
              </GlassEffect.GlassContainer>
            </Grid>
          
          </Grid>
      
        );
      case "Attendance":
        return (

          <Grid container sx={{ display: "flex", width: "100%", gap: "1.3rem", height: "70vh" }}>
           
            <Grid item width={"40%"} overflow={"hidden"}>
            <GlassEffect.GlassContainer>
              <DailyRecordsTable />
              </GlassEffect.GlassContainer>
            </Grid>
            <Grid item width={"55%"}>
            <GlassEffect.GlassContainer>
              <CalendarViewAttendence size={{ height: 520, width: "100%" }} getTimes={(month, year) => console.log(month, year)}/>
                </GlassEffect.GlassContainer>
            </Grid>
          </Grid>
        );
      case "Leave":
        return <Grid container sx={{ display: "flex", width: "100%", gap: "1.3rem", height: "70vh" }}>
        
          <Leave/>
        
        </Grid>;
      case "Payroll":
        return <div><Payrolle/></div>;
      case "Work History":
        return       <GlassEffect.GlassContainer>
          <Grid sx={{width : "100%", height: "83vh",  borderRadius: "10px", padding: "1rem", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)"}}>
              
              <WorkHistory/>
              
          </Grid>
          </GlassEffect.GlassContainer>
   
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "93%", gap: "1rem" }}>
      <Box pt={3} sx={{ width: "99%", display: "flex", flexDirection: "column", gap: "2rem" }}>
        <Grid container spacing={2} sx={{ width: "100%", position: "sticky", top: 0, zIndex: 999 }}>
          <Grid item xs={12} container justifyContent="flex-end">
            <ProfileNav />
          </Grid>
        </Grid>
      </Box>
      <Grid
        sx={{
          display: "flex",
          width: "97%",
          height: "2.5rem",
          alignItems: "center",
          justifyContent: "space-between",
          overflow: "hidden",
          backgroundColor: "#d2d2d4",
          borderRadius: "50px",
          transition: "background-color 300ms ease",
        }}
      >
        {tabs.map((tab) => (
          <Typography
            key={tab}
            onClick={() => handleTabChange(tab)}
            disabled={activeTab === tab}
            sx={{
              width: "30%",
              cursor: "pointer",
              color: activeTab === tab ? "#fff" : "#000",
              backgroundColor: activeTab === tab ? useTheme().palette.primary.main : "transparent",
              padding: "8px 15px",
              borderRadius: "50px",
              textAlign: "center",
              fontWeight: "300",
              transition: "background-color 500ms ease, color 500ms ease",
            }}
          >
            {tab}
          </Typography>
        ))}
      </Grid>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "1rem" }}>
        {renderContent()}
      </Box>
    </Box>
  );
}

export default EmplyoeeDetails;
