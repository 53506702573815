import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Grid,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useGet, usePost } from "../../../helpers/axios/useApi";
import LeadChangeDialog from "../sub-components/updateStage";
import { useAuth } from "../../../middlewares/auth";
import GridViewLead from '../sub-components/gridView';
import { Link, useLocation } from "react-router-dom";
import SyncIcon from '@mui/icons-material/Sync';
import axios from "axios";
import { server_url } from "../../../utils/server";
import { useDispatch, useSelector } from "react-redux";
const GridLead = ({ Updated }) => {
  const [initialData, setInitialData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentLead, setCurrentLead] = useState(null);
  const [draggingItem, setDraggingItem] = useState(null);
  const [destinationStatus, setDestinationStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { user } = useAuth();
  const [startDate, setStartDate] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const containerRef = useRef(null);
  const [teams, setTeams] = useState([]);
  const [employeesData, setEmployees] = useState([]);
  const [departmentsData, setDepartmentsData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [loadingMore, setLoadingMore] = useState(false)
  const { toggle ,range} = useSelector(state => state.leads);

  const [filters, setFilters] = useState({
    activeFilters: {},
    contain: {}
  });
  const [leads, setLeads] = useState(null);

  const statuses = ["Create", "Follow", "Booking", "Session", "Payment", "Success", "DNP", "Not_Interested", "Invalid"];
  const { canvanFilter } = useSelector(state => state.leads);
  const dispatch = useDispatch();
  const location = useLocation();
  const fetchLeads = async (Queryurl) => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get(`${server_url}/leads/get${Queryurl}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const fetchedLeads = response?.data?.data?.leadsData;
      const count = response?.data?.data.totalCountsByStage;
      setLeads(count);
      return fetchedLeads;
    } catch (error) {
      console.error("Error fetching leads:", error);
      setErrorMessage("Failed to fetch leads. Please try again.");
      return [];
    }
  };
  const fetchAllLeads = useCallback(async () => {
    const baseParams = new URLSearchParams();
    baseParams.set('page', page);
    baseParams.set('limit', rowsPerPage);

    // Handle active filters
    Object.entries(filters.activeFilters).forEach(([key, values]) => {
        const valueArray = Array.isArray(values) ? values : [values];
        const joinedValues = valueArray.map(encodeURIComponent).join(',');
        baseParams.set(`activeFilters[${key}]`, joinedValues);
    });

    // Handle containment filters
    Object.entries(filters.contain).forEach(([key, value]) => {
        const joinedValues = Array.isArray(value) 
            ? value.map(encodeURIComponent).join(',') 
            : encodeURIComponent(value);
        baseParams.set(`contain[${key}]`, joinedValues);
    });

    const newUrl = `${window.location.pathname}?${baseParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);

    const hasActiveFilters = Array.from(baseParams.keys()).some(key => key.startsWith('activeFilters['));

    const leadPromises = statuses.map(status => {
        // Create a new instance of URLSearchParams for each status
        const params = new URLSearchParams(baseParams);
        params.append(`activeFilters[stage]`, status);  // Add the current status
        params.set('contain[stage]', 'true');  // Ensure containment for stage
        return fetchLeads(`?${params.toString()}`);
    });

    const allLeadsResults = await Promise.allSettled(leadPromises);
    const combinedLeads = allLeadsResults
        .filter(result => result.status === "fulfilled")
        .flatMap(result => result.value);

    if ( page > 1) {
        // Append new leads if no active filters and loading more
        setInitialData(prev => [...prev, ...combinedLeads]);
        setLoadingMore(false);
    } else {
        // Reset data if there are active filters or if it's the first page
        setInitialData(combinedLeads);
    }

}, [page, rowsPerPage, filters, canvanFilter, location.search, range]);
 // Ensure statuses is included in dependencies

  useEffect(() => {
    fetchAllLeads();
  }, [fetchAllLeads]);

  const { data: employees, isLoading: employeesLoading } = useGet("employee/all", {}, {}, { queryKey: "employees" });
  const { data: departments, isLoading: departmentsLoading } = useGet("department/all", {}, {}, { queryKey: "departments" });
  const { data: teamsData, isLoading: teamsLoading } = useGet(`department/team-get/`, { departmentId: selectedDepartment }, { enabled: Boolean(selectedDepartment) });
  const { mutate: updateLead, isLoading: updateLeadLoading } = usePost("leads/update");

  useEffect(() => {
    if (departments?.data?.message[0]) {
      setDepartmentsData(departments.data.message[0]);
    }
  }, [departments]);

  useEffect(() => {
    if (employees?.data?.message[0] && selectedTeam) {
      const filteredEmployees = employees.data.message[0].filter((employee) =>
        employee.team.some((teamId) => teamId === selectedTeam)
      );
      setEmployees(filteredEmployees);
    }
  }, [employees, selectedTeam]);

  useEffect(() => {
    if (teamsData?.data?.message[0]) {
      setTeams(teamsData.data.message[0]);
    }
  }, [teamsData]);

  const handleFilterApply = async () => {
    const savedFilters = localStorage.getItem('columnFilters');
    
    if (savedFilters) {
      
      setPage(1)
      try {
        const parsedFilters = JSON.parse(savedFilters);
        if (typeof parsedFilters.activeFilters !== 'object' || typeof parsedFilters.contain !== 'object') {
          throw new Error('Invalid filter structure');
        }
        setFilters(parsedFilters);
        Object.entries(filters.activeFilters).forEach(([key, values]) => {
          const valueArray = Array.isArray(values) ? values : [values];
          const joinedValues = valueArray.map(encodeURIComponent).join(',');
          baseParams.set(`activeFilters[${key}]`, joinedValues);
        });
    
        Object.entries(filters.contain).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            const joinedValues = value.map(encodeURIComponent).join(',');
            baseParams.set(`contain[${key}]`, joinedValues);
          } else {
            baseParams.set(`contain[${key}]`, encodeURIComponent(value));
          }
        });
        
        await fetchAllLeads();
        dispatch({ type: "APPLY_CANVAN_FILTER", payload: 'Filter' });
      } catch (error) {
        console.error('Error parsing filters:', error);
      }
    }
    if(canvanFilter === false){
       setFilters({
        activeFilters: {},
         contain: {}
       })
       setInitialData([])
       await fetchAllLeads();
    }

  };

  useEffect(()=>{
    const savedFilters = localStorage.getItem('columnFilters');
    
    if (savedFilters) {
      setPage(1)
      try {
        const parsedFilters = JSON.parse(savedFilters);
        if (typeof parsedFilters.activeFilters !== 'object' || typeof parsedFilters.contain !== 'object') {
          throw new Error('Invalid filter structure');
        }
        setFilters(parsedFilters);
        setInitialData([])
         fetchAllLeads();
         dispatch({ type: "APPLY_CANVAN_FILTER",  payload: 'Filter' });
      } catch (error) {
        console.error('Error parsing filters:', error);
      }
    }

  },[range])

  const handleDragEnd = (result) => {
    setDraggingItem(null);
    
    if (!result.destination) return;

    const { source, destination } = result;
    const newStatus = destination.droppableId;

    if (source.droppableId !== newStatus) {
      const draggingLead = initialData.find(item => item.leadId === result.draggableId);
      setDraggingItem(result.draggableId);
      setDestinationStatus(newStatus);
      setCurrentLead(draggingLead);
      setDialogOpen(true);
    }
  };

  const getEmployeeName = (id) => {
    const employee = employees?.data?.message[0]?.find((employee) => employee._id === id);
    return employee ? ` ${employee.name}` : '';
  };

  const totalDocuments = useCallback((stage) => {
    return initialData?.filter((item) => item.stage === stage).length;
  }, [initialData]);

  const handleSuccess = () => {
    setInitialData(prevData =>
      prevData.map((item) =>
        item.leadId === draggingItem
          ? { ...item, stage: destinationStatus }
          : item
      )
    );
    resetDragState();
    Updated(true);
  };

  const resetDragState = () => {
    setDraggingItem(null);
    setDestinationStatus("");
    setCurrentLead(null);
    setStartDate("");
    setSelectedDepartment("");
    setSelectedTeam("");
    setSelectedEmployee("");
    setDialogOpen(false);
  };

  const loadMoreLeads = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop <= clientHeight + 50) {
      setPage((prevPage) => prevPage + 1);
      setLoadingMore(!loadingMore)
    }
   
  };

  const handleDragStart = (start) => {
    clearInterval(window.scrollInterval);
    const container = containerRef.current;

    const scrollContainer = () => {
      if (!container) return;
      const scrollLeft = container.scrollLeft;
      const containerWidth = container.offsetWidth;

      if (start.source.index * 250 > scrollLeft + containerWidth - 100) {
        container.scrollLeft += 20;
      } else if (start.source.index * 250 < scrollLeft + 100) {
        container.scrollLeft -= 20;
      }
    };

    window.scrollInterval = setInterval(scrollContainer, 100);
  };

  useEffect(() => {
    handleFilterApply();
  }, [canvanFilter]);
  
  const handleError = (error) => {
    console.error("Error updating lead details:", error);
    setErrorMessage("Failed to update lead. Please try again.");
  };
  const groupedInitialData = (status) => {
      
    console.log(status,'dsfds')
    if (!status || !initialData) return [];
    const filteredData = initialData?.filter((item) => item?.stage === status);
    console.log(filteredData,'filteredData')
    return filteredData;
  }

  return (
    <>
      <LeadChangeDialog
        open={dialogOpen}
        onClose={() => { setDialogOpen(false), localStorage.removeItem('zoomLink') }}
        currentLead={currentLead}
        employees={employees}
        departmentsData={departmentsData}
        teams={teams}
        employeesData={employeesData}
        onSuccess={handleSuccess}
        newStage={destinationStatus}
        destinationStatus={destinationStatus}
        onError={handleError}
        isLoading={employeesLoading || departmentsLoading || teamsLoading}
        lead={currentLead}
      />
      <Box width="100%" >
        <Snackbar
          open={!!errorMessage}
          autoHideDuration={6000}
          onClose={() => setErrorMessage("")}
        >
          <Alert onClose={() => setErrorMessage("")} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        <DragDropContext onDragEnd={handleDragEnd}  onDragStart={handleDragStart}>
          <Droppable droppableId="droppable" direction="horizontal" >
            {(provided) => (
              <Box
                {...provided.droppableProps}
                ref={(el) => {
                  provided.innerRef(el);
                  containerRef.current = el; // Set the ref for the container
                }}
                sx={{
                  width: "100%",
                  overflowX: "auto",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  backgroundColor: 'transparent',
                  padding: { xs: "8px", sm: "12px", md: "16px" },
                  mt: 0,
                  display: "flex",
                  gap: "0rem",
                  height: "80vh",
                }}
              >
                {[
                  "Create",
                  "Follow Up Required",
                  "Booking",
                  "Session",
                  "Payment",
                  "Success",
                  "DNP - Did Not Pick up",
                  "Not_Interested",
                  "Invalid Number",
                ].map((status) => (
                  <Box
                    key={status}
                    ref={provided.innerRef}
                    sx={{
                      width: "250px",
                      flex: "0 0 auto",
                      padding: "10px",
                      mb: 2,
                      textAlign: "center",
                      color: "grey",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: status === "Create" ? "#3a2f6b" : status === "Follow Up Required" ? "#36669c" : status === "Booking" ? "#41a0ae" : status === "Session" ? "#3ec995" : status === "Not Interested" ? "#b3aea8" : status === "DNP - Did Not Pick up" ? "#82591b" : status === "Invalid Number" ? "#2e2d2b" : "#3fbf4a",
                        width: "100%",
                        height: "40px",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                      }}
                    >
                      <Grid display="flex" flexDirection="column" gap={0}>
                        <Typography variant="h6" color="white" fontSize={"16px"}>
                          {status}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          sx={{
                            color: "white",
                            fontSize: "12px",
                            fontWeight: "bold",
                            height: "20px",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "max-content",
                            padding: "0px 5px",
                            borderRadius: "10px",
                            backgroundColor: "#4287f5",
                            boxShadow: "0 4px 6px rgba(0,0,0,0.5)",
                            position: "absolute",
                            top: "0px",
                            right: "20px",
                          }}
                        >
                      {leads &&
  (leads.find(lead => lead.stage === status)?.count || 0) 
}
                        </Typography>
                      </Grid>
                    </Box>
                    <Droppable droppableId={status} type="TASK">
                      {(provided) => (
                         <Box
                         ref={(el) => {
                           provided.innerRef(el);
                           containerRef.current = el; // Set the ref for the container
                         }}
                         {...provided.droppableProps}
                         onScroll={(e) => loadMoreLeads(e, status)}
                         sx={{
                           height: "66vh", // Fixed height for vertical scrolling
                           marginTop: "10px",
                           position: "relative",
                           overflowY: "auto", // Enable vertical scrolling
                           overflowX: "hidden", // Disable horizontal scrolling
                           "&::-webkit-scrollbar": {
                             display: "none", // Optional: hide the scrollbar
                           },
                         }}
                       >
                          <Box>
                          {groupedInitialData(status).map((item, index) => (
                            <Draggable
                              key={item.leadId}
                              ref={provided.innerRef}
                              draggableId={item.leadId}
                              index={index}
                              isDragDisabled={status === "Success"}
                            >
                              {(provided) => (
                                <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                mt={1}
                              >
                                  <Link to={`/leads/${item._id}`} style={{ textDecoration: 'none', color: 'black' }}>
                                    <GridViewLead
                                      leadId={item.leadId}
                                      leadName={item.leadName}
                                      city={item.city}
                                      email={item.email}
                                      mobile={item.mobile}
                                      assignee={getEmployeeName(item.assignee[0]) || "Unassigned"}
                                      owner={getEmployeeName(item.owner) || user.companyName}
                                      reportingManager={getEmployeeName(item.reportingManager) || "Not Assigned"}
                                      source={item.source}
                                      createdAt={item.created}
                                      type={item.type}
                                      stage={item.stage}
                                      newStage={item.newStage}
                                      newLead={item}
                                    />
                                  </Link>
                                </Box>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                          </Box>
                        </Box>
                      )}
                    </Droppable>
                  </Box>
                ))}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </>
  );
};

export default GridLead;
