import React from "react";
import ReactApexChart from "react-apexcharts";
import { Paper } from "@mui/material";
import { useTheme } from "@emotion/react";

const Chart = ({ data }) => {
  // Extract values from the passed data
  const totalLeaves = data?.value || 0; // Total leaves taken (fallback to 0 if missing)
  const remainingLeaves = data?.remaining || 0; // Remaining leaves (fallback to 0 if missing)
  const leaveType = data?.name || "Unknown Leave"; // Leave type (fallback to "Unknown Leave" if missing)

  // Define updated darker color schemes for each leave type
  const colors = {
    "Annual Leave": "#4CAF50",  // Soft Green (Modern and calming)
    "Sick Leave": "#FF7043",    // Soft Coral (Gentle, warm tone)
    "Casual Leave": "#FFB74D",  // Light Amber (Warm and inviting)
    "Remaining Leaves": "#8fcfb3", // Light Red (Attention-grabbing yet soft)
    "Unknown Leave": "#8e44ad",  // Default purple for unknown or mismatched types
  };

  // Get the color for the used leaves based on the leave type name
  const usedLeavesGradient = colors[leaveType] || colors["Unknown Leave"]; // Default to "Unknown Leave" color

  // Chart options and data for ApexCharts
  const options = {
    chart: {
      type: 'donut',
      toolbar: {
        show: false,  // Show the toolbar (you can hide it if needed)
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradients: true,  // Add smooth gradient animation on load
      },
    },
    colors: [usedLeavesGradient, colors["Remaining Leaves"]],  // Used and Remaining leaves colors
    labels: ['Used Leaves', 'Remaining Leaves'],  // Sections for used and remaining leaves
    plotOptions: {
      pie: {
        donut: {
          size: '10%',  // Adjust donut size for a more prominent central hole
          background: 'transparent',
        },
      },
    },
    dataLabels: {
      enabled: false,  // Disable data labels
    },
    tooltip: {
      enabled: true,  // Enable tooltips
      followCursor: true,
      offsetX: 10, // Position tooltip slightly below the chart
      
      theme: 'dark',  // Dark tooltip theme
    },
    stroke: {
      width: 0,  // Slightly thicker border to enhance separation
      colors: ['grey'],  // White border for the donut segments
    },
    legend: {
      show: true, // Show legend
      position: 'bottom', // Position the legend at the bottom
      horizontalAlign: 'left', // Align legend items horizontally
      labels: {
        colors: useTheme().palette.text.primary, // Set the legend text color to white
        fontSize: '14px', // Font size for the legend text
        fontFamily: 'Arial, sans-serif', // Font family for the legend text
      },
      markers: {
        width: 10, // Width of the marker (color box)
        height: 10, // Height of the marker (color box)
        radius: 0, // Make the marker square, set to 50% for circles
        offsetX: -10, // Adjust the horizontal distance between the marker and the text
      },
      offsetX: 0, // Adjust horizontal position (if needed)
      offsetY: 0, // Adjust vertical position (if needed)
    },
    dropShadow: {
      enabled: true,
      top: 5,
      left: 5,
      blur: 4,
      opacity: 0.2,
    },
  };

  // Donut chart data series for used and remaining leaves
  const series = [totalLeaves - remainingLeaves, remainingLeaves];  // Used and remaining leaves

  return (
    <div id="chart" style={{ height: '100%', width: '100%' }}>
      <ReactApexChart
        options={options || {}}
        series={series || []}
        type="donut"
        height="100%" // Fill the parent container's height
      />
    </div>
  );
};

export default Chart;
