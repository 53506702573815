import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
  Box,
  CircularProgress,
} from '@mui/material';
import { useGet } from '../../../helpers/axios/useApi';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';

const DailyRecordsTable = () => {
  const userId = useParams().id;
  const [selectedMonth, setSelectedMonth] = useState(DateTime.local().month);
  const [selectedYear, setSelectedYear] = useState(DateTime.local().year);
  const [entries, setEntries] = useState([]);
  const [totalWorkTime, setTotalWorkTime] = useState('00:00:00');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { data: policyData, error: policyError } = useGet("company/policy/attendece-get", { employeeId: userId });
  const formatDuration = (durationMs) => {
    const hours = String(Math.floor(durationMs / 3600000)).padStart(2, '0');
    const minutes = String(Math.floor((durationMs % 3600000) / 60000)).padStart(2, '0');
    const seconds = String(Math.floor((durationMs % 60000) / 1000)).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  const { data: dailyRecords, error: fetchError } = useGet(
    'employee/work-tracking/daily-records',
    {
      userId,
      startDate: DateTime.fromObject({ year: selectedYear, month: selectedMonth, day: 1 }).toISODate(),
      endDate: DateTime.fromObject({ year: selectedYear, month: selectedMonth }).endOf('month').toISODate(),
    }
  );

  useEffect(() => {
    if (fetchError) {
      setError('Failed to fetch daily records. Please try again later.try again ');
      setLoading(false);
      return;
    }
    if (dailyRecords) {
      const fetchedEntries = dailyRecords?.data?.data?.records || [];
      const sortedEntries = fetchedEntries.sort((a, b) => DateTime.fromISO(b.day) - DateTime.fromISO(a.day));
      setEntries(sortedEntries);
      setTotalWorkTime(formatDuration(dailyRecords?.data?.data?.totalWorkTime * 3600000));
      setLoading(false);
    }
  }, [dailyRecords, fetchError]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const convertToWorkDays = (totalWorkTime, workingHours) => {
    // Helper function to calculate daily work time in seconds
    const calculateDailyWorkTime = (start, end) => {
      const startTime = DateTime.fromFormat(start || '00:00', 'HH:mm');
      const endTime = DateTime.fromFormat(end || '00:00', 'HH:mm');
  
      // Calculate the difference in seconds between start and end time
      const duration = endTime.diff(startTime, 'seconds').seconds;
      return duration;
    };
  
    // Step 1: Calculate daily work time in seconds
    const dailyWorkTime = calculateDailyWorkTime(workingHours?.start, workingHours?.end); // in seconds
    
    // Step 2: Convert totalWorkTime into seconds (assuming totalWorkTime is in HH:mm:ss format)
    const [hours, minutes, seconds] = totalWorkTime.split(':').map(Number);
    const totalSeconds = hours * 3600 + minutes * 60 + seconds; // Total work time in seconds
  
    // Step 3: Calculate full work days and remaining time
    const fullWorkDays = Math.floor(totalSeconds / dailyWorkTime); // Number of full workdays
    const remainingSeconds = totalSeconds % dailyWorkTime; // Remaining time after full workdays
  
    // Step 4: Convert remaining seconds into hours, minutes, and seconds
    const remainingHours = Math.floor(remainingSeconds / 3600);
    const remainingMinutes = Math.floor((remainingSeconds % 3600) / 60);
    const remainingSecondsLeft = remainingSeconds % 60;
  
    // Step 5: Format the output
    if (fullWorkDays > 0) {
        
      return `${fullWorkDays} day${fullWorkDays > 1 ? 's' : ''} ${remainingHours}h ${remainingMinutes}m ${remainingSecondsLeft}s`;
    } else {
      return `${remainingHours}h ${remainingMinutes}m ${remainingSecondsLeft}s`;
    }
  };
  

  
  const result = convertToWorkDays(totalWorkTime, policyData?.data?.data?.workingHours);
  return (
    <Box sx={{
      width: "100%",
      borderRadius: "10px",
      padding: "1rem",
      boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
      backgroundColor: "rgba(255, 255, 255, 0.4)",
      height: "80vh",
      overflow: "auto",
    }}>
      <Grid container spacing={2} style={{ marginTop: 0, position: 'sticky', top: 0, zIndex: 1, justifyContent: 'center', }}>
        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" fullWidth sx={{
            borderRadius: '8px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
            transition: 'all 0.3s',
            '&:hover': {
              boxShadow: '0 8px 40px rgba(0, 0, 0, 0.3)',
            },
          }}>
            
            <Select
              value={selectedMonth}
              onChange={handleMonthChange}
              label="Month"
              color='grey'
         
              sx={{
                '& .MuiSelect-select': {
                  background: 'white',
                  color: 'black',
                  borderRadius: '5px',
                  padding: '10px 14px',
                  transition: 'background 1s',
                },
                
              }}
            >
              {Array.from({ length: 12 }, (_, i) => i + 1).map(month => (
                <MenuItem key={month} value={month}>
                  {DateTime.fromObject({ month }).toFormat('MMMM')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" fullWidth sx={{
            borderRadius: '8px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
            transition: 'all 0.3s',
            
          }}>
          
            <Select
              value={selectedYear}
              onChange={handleYearChange}
              label="Year"
              sx={{
                '& .MuiSelect-select': {
                  background: 'white',
                  color: 'black',
                  borderRadius: '8px',
                  padding: '10px 14px',
                  transition: 'background 0.3s',
                },
               
              }}
            >
              {Array.from({ length: 5 }, (_, i) => DateTime.local().year - i).map(year => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* <Typography style={{
        marginTop: 16,
        fontWeight: 'bold',
        position: 'sticky',
        top: '130px',
        backgroundColor: '#fff',
        zIndex: 1,
        padding: '10px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        transition: 'opacity 0.5s ease-in-out',
        opacity: loading ? 0 : 1,
      }}>
        Total Work Time for {DateTime.fromObject({ year: selectedYear, month: selectedMonth }).toFormat('MMMM yyyy')}: {result}
      </Typography> */}

      </Grid>

      {/* Total Work Time Section */}
    
      {/* Table Container */}
      <TableContainer component={Paper} sx={{
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
        marginTop: 2,
        opacity: loading ? 0 : 1,
        transition: 'opacity 0.5s ease-in-out',
      }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{  fontWeight: 'bold' }}>Date</TableCell>
                <TableCell style={{  fontWeight: 'bold' }}>First Punch In</TableCell>
                <TableCell style={{  fontWeight: 'bold' }}>Last Punch In</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Total Entries</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Total Working Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {entries.map((entry, index) => (
                <TableRow key={index} sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    transform: 'scale(1.02)',
                    transition: 'transform 0.2s',
                  }
                }}>
                  <TableCell>{DateTime.fromISO(entry.day).toFormat('MM/dd/yyyy')}</TableCell>
                  <TableCell>{entry.firstPunchIn ? DateTime.fromISO(entry.firstPunchIn).toFormat('hh:mm:ss a') : 'N/A'}</TableCell>
                  <TableCell>{entry.lastPunchIn ? DateTime.fromISO(entry.lastPunchIn).toFormat('hh:mm:ss a') : 'N/A'}</TableCell>
                  <TableCell>{entry.totalEntries}</TableCell>
                  <TableCell>{entry.totalWorkingTime ? formatDuration(entry.totalWorkingTime * 3600000) : '00:00:00'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      {error && <Typography variant="body2" color="error" style={{ marginTop: 16 }}>{error}</Typography>}
    </Box>
  );
};

export default DailyRecordsTable;
