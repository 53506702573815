import React, { useMemo, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Drawer, AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import marker from '../../../../assets/images/image.png';

// Custom marker icon settings
const createCustomIcon = (count) => {
  const iconElement = document.createElement('div');
  iconElement.className = 'marker-container';
  
  // Create green layer for animation
  const greenLayer = document.createElement('div');
  greenLayer.className = 'green-layer';

  const countElement = document.createElement('div');
  countElement.className = 'count';
  countElement.innerText = count;

  iconElement.appendChild(greenLayer);
  iconElement.appendChild(countElement);
  
  return new L.DivIcon({
    className: 'custom-marker',
    html: iconElement.outerHTML,
    iconSize: [50, 50], // Adjust as necessary
    iconAnchor: [25, 25],
  });
};

const MapComponent = ({ locations, count = true }) => {
  const initialPosition = locations?.length > 0 
    ? [locations[0].location.lat, locations[0].location.lon] 
    : [0, 0];
  const zoomLevel = locations?.length > 0 ? 6 : 2;

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const clusterLocations = (locations) => {
    const clusterRadius = 0.01; 
    const clusters = [];

    locations?.forEach(location => {
      const cluster = clusters.find(c => {
        const distance = Math.sqrt(
          Math.pow(c.latitude - location.location.lat, 2) +
          Math.pow(c.longitude - location.location.lon, 2)
        );
        return distance < clusterRadius;
      });

      if (cluster) {
        cluster.locations.push(location);
        cluster.latitude = (cluster.latitude + location.location.lat) / 2;
        cluster.longitude = (cluster.longitude + location.location.lon) / 2;
        cluster.count = location.count;
      } else {
        clusters.push({ 
          latitude: location.location.lat, 
          longitude: location.location.lon, 
          locations: [location],
          count: location.count
        });
      }
    });

    return clusters;
  };

  const clusteredLocations = useMemo(() => clusterLocations(locations), [locations]);

  const handleMarkerClick = (locations) => {
    setSelectedLocation(locations);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedLocation(null);
  };

  return (
    <div style={{
      display: 'flex',
      backgroundColor: 'white',
      borderRadius: '15px',
      boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.2)',
      padding: '0px',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    }}>
      <style>
        {`
          .marker-container img {
            display: none; /* Hide the image */
          }
          .marker-container {
            position: relative;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .green-layer {
            width: 20px;
            height: 20px;
            background-color: #d169b2;
            border-radius: 50%;
            position: absolute;
            animation: zoomInOut 1.5s infinite; /* Animation */
          }
          @keyframes zoomInOut {
            0%, 100% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.2); /* Scale up */
            }
          }
          .count {
            position: absolute;
            color: white;
            font-size: 10px;
            font-weight: bold;
            z-index: 2; /* Ensure count is above both shadow and marker */
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        `}
      </style>

      <MapContainer center={initialPosition} zoom={zoomLevel} style={{ height: '100vh', width: '100%' }}>
        <TileLayer url={'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'} />
        {clusteredLocations?.map((cluster, index) => (
          <ClusterMarker 
            key={index} 
            cluster={cluster} 
            locations={cluster.locations} 
            onClick={handleMarkerClick}
          />
        ))}

        {/* Render markers with counts when count is true */}
        {count && locations?.map((location, index) => (
          location.count > 0 && (
            <Marker 
              key={index} 
              position={[location.location.lat, location.location.lon]} 
              icon={createCustomIcon(location.count)} // Use the custom icon with count
            >
              <Popup>
                <div className="instant-marker">
                  Count: {location.count}
                </div>
              </Popup>
            </Marker>
          )
        ))}
      </MapContainer>

      
    </div>
  );
};

// ClusterMarker component
const ClusterMarker = ({ cluster, locations, onClick }) => {
  const map = useMap();

  return (
    <Marker 
      position={[cluster.latitude, cluster.longitude]} 
      icon={createCustomIcon(cluster.count)}
      eventHandlers={{
        click: () => {
          const bounds = L.latLngBounds(locations.map(loc => [loc.location.lat, loc.location.lon]));
          map.fitBounds(bounds, { padding: [0, 0] });
          onClick(locations);
        }
      }}
    >
     
    </Marker>
  );
};

export default MapComponent;
