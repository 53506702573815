import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Grid,
  TextField,
  Button,
  IconButton,
  Modal,
  Fade,
  Backdrop,
  Divider,
  keyframes,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { jsPDF } from "jspdf";
import { height } from "@mui/system";
import { useGet, usePost } from "../../../helpers/axios/useApi";
import { useParams } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import SalarySlipPDF from "./salrySlip";
import SalarySlip from "./salrySlip";
import { DateTime } from "luxon";
import GlassEffect from "../../../theme/glassEffect";
export default function SalesSummaryCard({ data, sales }) {
  const id = useParams().id;
  const currentYear = new Date().getFullYear();  // Get the current year
  let currentMonth = new Date().getMonth(); // 0 (January) to 11 (December)

  // Default the month to the previous month (current month - 1)
  // If the current month is January (0), default to December (11)
 
  
  const [payrollData, setPayrollData] = useState({
    employeeId: id,
    basePay: 0,
    bonus: 0,
    deductions: 0,
    tax: 0,
    allowance: 0,
    netPay: 0,
    totalAllowance: 0,
    targetSalesAmount: 0,
    gstRate: 0,
    otherTaxRate: 0,
    incentive: [{ minSalesAmount: 0, maxSalesAmount: 0, percentage: 0 }],
    additionalAllowances: [],
    additionalDeductions: [],
  });
  const [payDatas, setPayData] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar open state
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Snackbar message
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [totalSales, setTotalSales] = useState(0);
  const [company, setCompany] = useState({});
  const [employee, setEmployee] = useState({});
  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => setOpenEditModal(false);
  const handleCreatePayRoll = usePost("/company/payroll/create");
  const [incentives, setIncentives] = useState([]);
  const [slipData, setSlipData] = useState({});
  const [month, setMonth] = useState(currentMonth-1);
  const [year, setYear] = useState(currentYear);
  const [leaveData, setLeaveData] = useState([]);
  const [dailyRecordsData, setDailyRecordsData] = useState([]);
  const { data: info, loading: salesLoading } = useGet(
    "/company/payroll/total-sales-data",
    {
      employeeId: id,
      month : month + 1,
      year,
    }
  );
  const { data: dailyRecords, error: recordsError, refetch: refetchRecords } = useGet("employee/work-tracking/daily-records", {
    userId: id,
    startDate: DateTime.fromObject({ year: currentYear, month: month +1 , day: 1}).toISODate(),
    endDate: DateTime.fromObject({ year: currentYear, month: month+1 }).endOf("month").toISODate(),
  });
  const { data: leaves , isLoading, refetch: refetchLeaves} = useGet('employee/leave/get-by-id', {
    employeeId: id
  })

  const { data: policyData, error: policyError } = useGet("company/policy/attendece-get", { employeeId: id });
  
  const { data: payData, refetch } = useGet("/company/payroll/get", {
    employeeId: id,
  });
  const { data: emp } = useGet("/company/payroll/get-info", {
    emplyoeeId: id,
  });
  console.log(emp, "emp");
  useEffect(() => {
    if (payData?.data?.data) {
      setPayData(payData?.data?.data);
    }
    if (emp?.data?.data) {
      setEmployee(emp?.data?.data?.employee);
      setCompany(emp?.data?.data?.company);
    }
    if (leaves?.data?.data) {
      setLeaveData(leaves?.data?.data);
    }
    if (dailyRecords?.data?.data) {
      setDailyRecordsData(dailyRecords?.data?.data);
    }
    console.log(payrollData);
  }, [info, data]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setPayrollData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Functionality for Incentives
  const handleAddIncentive = () => {
    setPayrollData((prevData) => ({
      ...prevData,
      incentive: [
        ...prevData.incentive,
        { minSalesAmount: 0, maxSalesAmount: 0, percentage: 0 },
      ],
    }));
  };

  const handleIncentiveChange = (index, field, value) => {
    const updatedIncentives = [...payrollData.incentive];
    updatedIncentives[index][field] = value;
    setPayrollData((prevData) => ({
      ...prevData,
      incentive: updatedIncentives,
    }));
  };

  const handleDeleteIncentive = (index) => {
    const updatedIncentives = payrollData.incentive.filter(
      (_, i) => i !== index
    );
    setPayrollData((prevData) => ({
      ...prevData,
      incentive: updatedIncentives,
    }));
  };

  // Functionality for Additional Allowances
  const handleAddAllowance = () => {
    setPayrollData((prevData) => ({
      ...prevData,
      additionalAllowances: [
        ...prevData.additionalAllowances,
        { name: "", value: 0 },
      ],
    }));
  };

  const handleAllowanceChange = (index, field, value) => {
    const updatedAllowances = [...payrollData.additionalAllowances];
    updatedAllowances[index][field] = value;
    setPayrollData((prevData) => ({
      ...prevData,
      additionalAllowances: updatedAllowances,
    }));
  };

  const handleDeleteAllowance = (index) => {
    const updatedAllowances = payrollData.additionalAllowances.filter(
      (_, i) => i !== index
    );
    setPayrollData((prevData) => ({
      ...prevData,
      additionalAllowances: updatedAllowances,
    }));
  };

  // Functionality for Additional Deductions
  const handleAddDeduction = () => {
    setPayrollData((prevData) => ({
      ...prevData,
      additionalDeductions: [
        ...prevData.additionalDeductions,
        { name: "", value: 0 },
      ],
    }));
  };

  const handleDeductionChange = (index, field, value) => {
    const updatedDeductions = [...payrollData.additionalDeductions];
    updatedDeductions[index][field] = value;
    setPayrollData((prevData) => ({
      ...prevData,
      additionalDeductions: updatedDeductions,
    }));
  };

  const handleDeleteDeduction = (index) => {
    const updatedDeductions = payrollData.additionalDeductions.filter(
      (_, i) => i !== index
    );
    setPayrollData((prevData) => ({
      ...prevData,
      additionalDeductions: updatedDeductions,
    }));
  };
  // Handle deleting an allowance or deduction

  const handleSaveChanges = async () => {
    try {
      const response = await handleCreatePayRoll.mutateAsync({
        payrollData: {
          ...payrollData,
          additionalAllowances: Array.isArray(payrollData.additionalAllowances)
            ? payrollData.additionalAllowances
            : [],
          additionalDeductions: Array.isArray(payrollData.additionalDeductions)
            ? payrollData.additionalDeductions
            : [],
        },
      });

      if (response?.error === null) {
        setSnackbarMessage("Payroll information saved successfully!");
        setSnackbarSeverity("success");
      } else {
        setSnackbarMessage("Failed to save payroll information.");
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setSnackbarMessage("An unexpected error occurred.");
      setSnackbarSeverity("error");
    }

    setOpenEditModal(false);
    setOpenSnackbar(true);
  };

  // Close snackbar handler
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Generate the salary slip PDF
  // Handle changes for the month selector
const handleMonthChange = (e) => {
    const selectedMonth = e.target.value;
    console.log(selectedMonth,'selectedMonth')
    setMonth(selectedMonth);  // Update the state with the selected month
  };
  
  // Handle changes for the year selector
  const handleYearChange = (e) => {
    const selectedYear = e.target.value;
    setYear(selectedYear);  // Update the state with the selected year
  };
  

  const scaleUp = keyframes`
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;
  // Helper function to calculate total sales
  // Helper function to calculate total sales
  // Helper function to calculate total sales
  // Helper function to calculate total sales
  // Helper function to calculate total sales
  const calculateTotalSales = (salesArray) => {
    return salesArray?.reduce((total, sale) => total + (sale.sales || 0), 0);
  };
  const getDaysInMonth = (month, year) => {
    const startOfMonth = DateTime.fromObject({ month: month + 1, year });
    const daysInMonth = startOfMonth.daysInMonth;
    const days = Array.from({ length: daysInMonth }, (_, index) => {
      const day = startOfMonth.set({ day: index + 1 });
      return day.toFormat("yyyy-MM-dd"); // Format as "2024-10-04"
    });
    
    return days;
  };
  

  const getDeductions = () => {
    // Get all days in the month (formatted as "YYYY-MM-DD")
    const days = getDaysInMonth(month, year);
    
    // Assuming 'workingDays' is an object where key 0 is Sunday, key 1 is Monday, etc.
    const workingDays = policyData?.data?.data[0]?.workingDays;
    console.log(policyData?.data?.data,'workingDays')
    // Check if 'workingDays' is an object and contains exactly 7 keys (0 to 6)
    if (typeof workingDays !== 'object' || Object.keys(workingDays).length !== 7) {
      console.error("Invalid 'workingDays' object. It must have exactly 7 keys (0 to 6).", workingDays);
      return; // Exit early if workingDays is invalid
    }
    
    // Ensure that each value in the workingDays object is a boolean
    for (let i = 0; i <= 6; i++) {
      if (typeof workingDays[i] !== 'boolean') {
        console.error(`Invalid value for 'workingDays[${i}]'. It must be a boolean.`);
        return; // Exit early if any value is not a boolean
      }
    }
  
    // Filter out days that are not working days based on the 'workingDays' object
    const filteredDays = days.filter((day) => {
      const date = DateTime.fromFormat(day, "yyyy-MM-dd"); // Parse the day to DateTime
      const dayOfWeek = date.weekday; // Get the day of the week (1 = Monday, 7 = Sunday)
  
      // Adjust the weekday index from (1-7) to (0-6) for correct mapping to workingDays
      const adjustedDayOfWeek = (dayOfWeek % 7); // Adjust so Sunday (7) becomes 0, Monday (1) becomes 1, etc.
      
      // Return whether the day is a working day based on the workingDays object
      return workingDays[adjustedDayOfWeek]; // If the day is a working day, keep it
    });
  
    console.log("Filtered Working Days:", filteredDays);
    
    // Now filteredDays contains only the days that are working days according to 'workingDays'
    
    // Get the days the employee was present
    const presentDays = dailyRecordsData?.records?.map(record => record.day);
    console.log(presentDays,'presentDays')
    // Create a set from presentDays for fast lookup
    const presentDaysSet = new Set(presentDays);
    
    // Filter days that are in 'filteredDays' but not in presentDaysSet
    const absentDays = filteredDays.filter(day => !presentDaysSet.has(day));
   console.log(leaves?.data?.data?.leaveRequests,'leaves?.data?.data?.leaveRequests')
   const leave = leaveData?.leaveRequests?.filter((leave) => {
    // Convert the leave.date to a JavaScript Date object
    const leaveDate = new Date(leave.date);
    const leaveMonth = `${(leaveDate.getMonth() + 1).toString().padStart(2, '0')}`; 
    console.log(leaveMonth,'leaveMonth', month + 1, 'month + 2', leave.status,'leave.status')
    return leave.status == 'Approved' && leaveMonth == month + 1;
  });
    
    let leaveDays = 0
    leave?.map((leave) => {
            if(leave.leaveDuration == 'Half Day') leaveDays += 0.5
            else leaveDays += 1
    } )

    const absentDaysCount = absentDays.length - leaveDays;
    return absentDaysCount
    
    // Now you can use the 'absentDays' variable to calculate deductions or further logic
  };
  
  
  
  
 
  // Function to calculate total incentives for self (corrected)
  const calculateTotalIncentives = (totalData) => {
    const Sale = calculateTotalSales(totalData);
    if (payData?.data?.data?.gstRate === 0) return 0;
    const totalSale = Sale / (1 + payData?.data?.data?.gstRate / 100);

    const incentives = payData?.data?.data?.incentive;

    if (!incentives || incentives.length === 0) {
      return 0;
    }

    let totalIncentive = 0;
    let remainingSale = totalSale; // Track the remaining sale amount to be processed

    // Iterate through each incentive slab to apply the correct percentage
    incentives.forEach((incentive, index) => {
      const { minSalesAmount, maxSalesAmount, percentage } = incentive || {};


      // For the first slab, apply the full sale amount up to the maximum of the slab
      if (remainingSale > minSalesAmount) {
        if (index === 0) {
          // Apply incentive on the first slab, up to the maxSalesAmount
          const salesInThisSlab = Math.min(remainingSale, maxSalesAmount);
          const incentiveAmount = (salesInThisSlab * percentage) / 100;
          totalIncentive += incentiveAmount;

          remainingSale -= salesInThisSlab; // Deduct the processed sales from remaining sales
        } else {
          // For subsequent slabs, apply incentive only on the remaining sales after the first slab
          const salesInThisSlab =
            Math.min(remainingSale, maxSalesAmount) - minSalesAmount;
          if (salesInThisSlab > 0) {
            const incentiveAmount = (salesInThisSlab * percentage) / 100;
            totalIncentive += incentiveAmount;

          }
          remainingSale -= salesInThisSlab; // Deduct the processed sales from remaining sales
        }

        // If no sales remain, exit the loop
        if (remainingSale <= 0) {
          return;
        }
      }
    });



    return Math.round(totalIncentive); // totalIncentive;
  };
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <Box sx={{ marginTop: 2 }}>
      
      <Paper
        elevation={12}
        sx={{
          padding: 3,
          borderRadius: 3,
          background: "transparent",
          boxShadow: "0px 15px 45px rgba(0, 0, 0, 0.1)",
          width: "100%",
          display:'flex',
          gap: "1rem",
        }}
      >
        
        <Grid>
        <GlassEffect.GlassContainer>
           <Grid sx={{padding:"1rem"}}>
          <Grid>
            <Typography
              variant="h5"
              sx={{ marginBottom: 0, textAlign: "center", fontWeight: "bold" }}
            >
              Payroll Information
            </Typography>
          </Grid>
          <Grid>
            <IconButton onClick={handleOpenEditModal}>
              <EditIcon sx={{ color: "#1976d2" }} />
            </IconButton>
          </Grid>
          <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <Box
              sx={{ boxShadow: "0 2px 4px rgba(0,0,0,0.5)", padding: 2, borderRadius: 2 }}
            >
              <Typography variant="body2">Total Sale</Typography>
              <Typography variant="h6">
                ₹{sales?.data?.data.totalSales}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              sx={{ boxShadow: "0 2px 4px rgba(0,0,0,0.5)",padding: 2, borderRadius: 2 }}
            >
              <Typography variant="body2">Base Pay</Typography>
              <Typography variant="h6">₹{payDatas?.basePay}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{ boxShadow: "0 2px 4px rgba(0,0,0,0.5)", padding: 2, borderRadius: 2 }}
            >
              <Typography variant="body2">Bonus</Typography>
              <Typography variant="h6">₹{payDatas?.bonus}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{ boxShadow: "0 2px 4px rgba(0,0,0,0.5)",padding: 2, borderRadius: 2 }}
            >
              <Typography variant="body2">Deductions</Typography>
              <Typography variant="h6">{ getDeductions()} Days</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              sx={{ boxShadow: "0 2px 4px rgba(0,0,0,0.5)", padding: 2, borderRadius: 2 }}
            >
              <Typography variant="body2">Total Self Sales</Typography>
              <Typography variant="h6">
                ₹{calculateTotalSales(info?.data?.data?.salesForEmployee)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{ boxShadow: "0 2px 4px rgba(0,0,0,0.5)", padding: 2, borderRadius: 2 }}
            >
              <Typography variant="body2">Total Sale By Team</Typography>
              <Typography variant="h6">
                ₹{calculateTotalSales(info?.data?.data?.salesForJuniors)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{ boxShadow: "0 2px 4px rgba(0,0,0,0.5)", padding: 2, borderRadius: 2 }}
            >
              <Typography variant="body2">
                Total Self Sales Incentive
              </Typography>
              <Typography variant="h6">
                ₹{calculateTotalIncentives(info?.data?.data?.salesForEmployee)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{boxShadow: "0 2px 4px rgba(0,0,0,0.5)", padding: 2, borderRadius: 2 }}
            >
              <Typography variant="body2">
                Total Sale By Team Incentive
              </Typography>
              <Typography variant="h6">
                ₹{calculateTotalIncentives(info?.data?.data?.salesForJuniors)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        </Grid>
        </GlassEffect.GlassContainer>
        </Grid>

        {/* Display Payroll Summary */}

        {/* Total Sale */}

        <GlassEffect.GlassContainer>
        <Box sx={{ textAlign: "center", marginTop: 3 , padding:"1rem"}}>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" , }}
          >
            
            <Box sx={{ marginTop: 0 , display:'flex' , justifyContent:'space-between',  alignItems:'center', flexDirection:'row-reverse'}}>
            <Typography sx={{ fontSize: "13px", fontWeight: "bold", width: "100%" , textAlign:'right'}}>
              Salary Slip for {`${monthNames[month]} ${year}`}
            </Typography>
      <Grid container spacing={2} justifyContent="left" alignItems="center">
        
        {/* Year Selector */}
        <Grid item>
          <FormControl size="small" sx={{ width: 120 }}>
            <InputLabel sx={{ fontSize: '12px' }}>Year</InputLabel>
            <Select
              value={year}
              onChange={handleYearChange}
              label="Year"
              size="small"
              sx={{
                '& .MuiSelect-select': {
                  padding: '3px 7px',
                },
                '& .MuiInputBase-input': {
                  fontSize: '12px',
                },
              }}
            >
              {/* Allow selection of the past 5 years */}
              {[...Array(5)]?.map((_, idx) => {
                const currentYearOption = currentYear - idx;
                return (
                  <MenuItem key={currentYearOption} value={currentYearOption}>
                    {currentYearOption}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        {/* Month Selector */}
        <Grid item>
          <FormControl size="small" sx={{ width: 120 }}>
            <InputLabel sx={{ fontSize: '12px' }}>Month</InputLabel>
            <Select
              value={month}
              onChange={handleMonthChange}
              label="Month"
              size="small"
              sx={{
                '& .MuiSelect-select': {
                  padding: '3px 7px',
                },
                '& .MuiInputBase-input': {
                  fontSize: '12px',
                },
              }}
            >
              {/* Allow only the current month and previous months */}
              {Array.from({ length: currentMonth +1 }, (_, i) => (
                <MenuItem key={i} value={i}>
                  {new Date(0, i).toLocaleString('default', { month: 'long' })}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        
      </Grid>
      
    </Box>
    

            <SalarySlip
              payrollData={payDatas}
              companyData={company}
              employeeData={employee}
              insativeData={{
                salesForEmployee: info?.data?.data?.salesForEmployee,
                salesForJuniors: info?.data?.data?.salesForJuniors,
              }}
              month={monthNames[month]}
              saleryMonth = {month}
              year={year}
              deductions = { getDeductions()}
            />
          </Paper>
        </Box>
        </GlassEffect.GlassContainer>
      </Paper>

      {/* Edit Payroll Form Modal */}
      <Modal
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        closeAfterTransition
      >
        <Fade in={openEditModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: 4,
              width: "90%",
              maxWidth: 600,
              height: "80vh",
              backgroundColor: "#fff",
              borderRadius: 3,
              boxShadow: 3,
              overflow: "auto",
            }}
          >
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                {snackbarMessage}
              </Alert>
            </Snackbar>

            <Typography variant="h5" sx={{ marginBottom: 3, fontWeight: 600 }}>
              Edit Payroll Information
            </Typography>

            {/* Base pay, bonus, deductions fields */}
            <TextField
              label="Base Pay"
              name="basePay"
              type="number"
              value={payrollData.basePay}
              onChange={handleFormChange}
              fullWidth
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Bonus"
              name="bonus"
              type="number"
              value={payrollData.bonus}
              onChange={handleFormChange}
              fullWidth
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Deductions"
              name="deductions"
              type="number"
              value={payrollData.deductions}
              onChange={handleFormChange}
              fullWidth
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Tatger Sales"
              name="targetSalesAmount"
              type="number"
              value={payrollData.targetSalesAmount}
              onChange={handleFormChange}
              fullWidth
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label=" Gst Rate"
              name="gstRate"
              type="number"
              value={payrollData.gstRate}
              onChange={handleFormChange}
              fullWidth
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Other Tax Rate"
              name="otherTaxRate"
              type="number"
              value={payrollData.otherTaxRate}
              onChange={handleFormChange}
              fullWidth
              sx={{ marginBottom: 2 }}
            />
            {/* Additional Allowances */}
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Additional Allowances
            </Typography>
            {payrollData.additionalAllowances?.map((allowance, index) => (
              <Box
                key={index}
                sx={{ display: "flex", gap: 2, marginBottom: 2 }}
              >
                <TextField
                  label={`Allowance Name ${index + 1}`}
                  name={`allowanceName-${index}`}
                  value={allowance.name}
                  onChange={(e) =>
                    handleAllowanceChange(index, "name", e.target.value)
                  }
                  fullWidth
                />
                <TextField
                  label="Allowance Value"
                  name={`allowanceValue-${index}`}
                  value={allowance.value}
                  onChange={(e) =>
                    handleAllowanceChange(index, "value", e.target.value)
                  }
                  type="number"
                  fullWidth
                />
                <IconButton
                  onClick={() => handleDeleteAllowance(index)}
                  color="error"
                >
                  Delete
                </IconButton>
              </Box>
            ))}
            <Button onClick={handleAddAllowance} sx={{ marginBottom: 2 }}>
              Add Allowance
            </Button>

            {/* Additional Deductions */}
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Additional Deductions
            </Typography>
            {payrollData.additionalDeductions?.map((deduction, index) => (
              <Box
                key={index}
                sx={{ display: "flex", gap: 2, marginBottom: 2 }}
              >
                <TextField
                  label={`Deduction Name ${index + 1}`}
                  name={`deductionName-${index}`}
                  value={deduction.name}
                  onChange={(e) =>
                    handleDeductionChange(index, "name", e.target.value)
                  }
                  fullWidth
                />
                <TextField
                  label="Deduction Value"
                  name={`deductionValue-${index}`}
                  value={deduction.value}
                  onChange={(e) =>
                    handleDeductionChange(index, "value", e.target.value)
                  }
                  type="number"
                  fullWidth
                />
                <IconButton
                  onClick={() => handleDeleteDeduction(index)}
                  color="error"
                >
                  Delete
                </IconButton>
              </Box>
            ))}

            <Button onClick={handleAddDeduction} sx={{ marginBottom: 2 }}>
              Add Deduction
            </Button>
            <Box>
              <Typography variant="h6" sx={{ marginBottom: 2 }}>
                Incentives
              </Typography>
              {payrollData.incentive.map((incentive, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", gap: 2, marginBottom: 2 }}
                >
                  <TextField
                    label={`Min Sales Amount ${index + 1}`}
                    name={`minSalesAmount-${index}`}
                    value={incentive.minSalesAmount}
                    onChange={(e) =>
                      handleIncentiveChange(
                        index,
                        "minSalesAmount",
                        e.target.value
                      )
                    }
                    fullWidth
                  />
                  <TextField
                    label={`Max Sales Amount ${index + 1}`}
                    name={`maxSalesAmount-${index}`}
                    value={incentive.maxSalesAmount}
                    onChange={(e) =>
                      handleIncentiveChange(
                        index,
                        "maxSalesAmount",
                        e.target.value
                      )
                    }
                    fullWidth
                  />
                  <TextField
                    label={`Percentage ${index + 1}`}
                    name={`percentage-${index}`}
                    value={incentive.percentage}
                    onChange={(e) =>
                      handleIncentiveChange(index, "percentage", e.target.value)
                    }
                    fullWidth
                  />
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteIncentive(index)}
                  >
                    ❌
                  </IconButton>
                </Box>
              ))}
              <Button
                onClick={handleAddIncentive}
                variant="outlined"
                sx={{ marginTop: 2 }}
              >
                Add Another Incentive
              </Button>
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveChanges}
              sx={{ marginTop: 3 }}
            >
              Save Changes
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}
