import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Typography,
  Box,
  Grid,
  TablePagination,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { DateTime } from "luxon";
import { useGet } from "../../../helpers/axios/useApi";
import GetLeaveStatusAndAction from "./GetLeave";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Padding } from "@mui/icons-material";
import AddPaymentMethodModal from "./Payment";
import GetPaymentDate from "./GetPaymentDate";
import { useAuth } from "../../../middlewares/auth";
import UpdateSalerySlips from "./UpdateSalerySlips";

// Sample Employee Data
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();

// Define the range of years: 100 years back to 100 years forward
const years = Array.from(
  { length: 201 },
  (_, index) => currentYear - 100 + index
);
const months = Array.from({ length: 12 }, (_, index) => index);

// Helper function to get the days in a given month using Luxon
const getDaysInMonth = (month, year) => {
  const startOfMonth = DateTime.fromObject({ month: month + 1, year });
  const daysInMonth = startOfMonth.daysInMonth;
  const days = Array.from({ length: daysInMonth }, (_, index) => {
    const day = startOfMonth.set({ day: index + 1 });
    return day.toFormat("d MMM yyyy"); // Format as "1 Oct 2024"
  });

  return days;
};
const getWeekdaysInMonth = (month, year) => {
  const startOfMonth = DateTime.fromObject({ month: month + 1, year });
  const daysInMonth = startOfMonth.daysInMonth;

  const weekdays = Array.from({ length: daysInMonth }, (_, index) => {
    const day = startOfMonth.set({ day: index + 1 });
    return day.weekday % 7; // Adjust Luxon weekday to start from 0 (Sunday)
  });

  return weekdays;
};
function EmployeeTable() {
  const [employeesData, setEmployeeData] = useState({}); // Employee attendance data
  const [selectedMonth, setSelectedMonth] = useState(currentMonth); // Default to October
  const [selectedYear, setSelectedYear] = useState(currentYear); // Default to 2024
  const [selectedCell, setSelectedCell] = useState(null); // Track the selected cell
  const [page, setPage] = useState(0); // Track the current page
  const [rowsPerPage, setRowsPerPage] = useState(11); // Number of rows per page
  const [employeeIds, setEmployeeIds] = useState([]); // Store employee IDs for the current page
  const [employees, setEmployee] = useState([]);
  const [paymentDate, setPaymentDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [paidStatus, setStatus] = useState(false);
  const [currentLeaves, setCurrentLeaves] = useState([])
  const [updateSalerySlipModel, setUpdatesalerySlipModel] = useState(false)
  
  const {user} = useAuth()
  const {
    data: employee,
    refetch: refetchEmployees,
    isLoading,
  } = useGet("employee/all", {}, {}, { queryKey: "employees" });
  const { data: leaves, refetch } = useGet(
    "employee/leave/get-all-by-month",
    {
      month: selectedMonth + 1,
      year: selectedYear,
    },
    {},
    { queryKey: "leave" }
  );

  const { data: payment } = useGet(
    "/company/salery/get-model",
    {},
    {},
    { queryKey: "payment" }
  );
  useEffect(() => {
    
    if (leaves?.data?.data) {
      setCurrentLeaves(leaves?.data?.data);
    }
  }, [leaves]);
  useEffect(() => {
    if (employee?.data?.message) {
      setEmployee(employee?.data?.message[0]);
    }
    if (payment?.data) {
      setPaymentDate(payment?.data?.data);
    }
  }, [employee, payment]);
  // API Call to fetch daily records and policy data
  const { data: dailyRecords, error: recordsError } = useGet(
    "employee/work-tracking/daily-records-multipal",
    {
      userId: employeeIds.join(","),
      startDate: DateTime.fromObject({
        year: selectedYear,
        month: selectedMonth + 1,
        day: 1,
      }).toISODate(),
      endDate: DateTime.fromObject({
        year: selectedYear,
        month: selectedMonth + 1,
      })
        .endOf("month")
        .toISODate(),
    },
    { enabled: employeeIds.length > 0 }, // Only make the call when employeeIds is not empty
    { queryKey: "dailyRecords" }
  );

  const { data: policyData, error: policyError } = useGet(
    "company/policy/attendece-get",
    { employeeId: employeeIds.join(",") },
    { enabled: employeeIds.length > 0 },
    { queryKey: "policyData" }
  );

  // Deep merge function for handling nested objects and arrays
  const deepMerge = (target, source) => {
    if (
      target &&
      source &&
      typeof target === "object" &&
      typeof source === "object"
    ) {
      Object.keys(source).forEach((key) => {
        if (Array.isArray(target[key]) && Array.isArray(source[key])) {
          target[key] = [...target[key], ...source[key]];
        } else if (
          typeof target[key] === "object" &&
          typeof source[key] === "object"
        ) {
          target[key] = deepMerge({ ...target[key] }, source[key]);
        } else {
          target[key] = source[key];
        }
      });
    }
    return target;
  };

  const fetchEmployeeData = async () => {
    if (recordsError || policyError) {
      return;
    }

    const employeeData = policyData?.data?.data || [];
    const attendanceData = dailyRecords?.data?.data?.employees || [];

    // Merge employee data with attendance data
    const mergedData = employeeData.map((employee) => {
      const attendance = attendanceData.find(
        (att) => att.userId === employee.employeeId
      );

      // Merge the employee data with the attendance (currentStatus and records)
      const mergedEmployeeData = deepMerge(
        { ...employee },
        {
          currentStatus: attendance?.currentStatus || {
            isPunchedIn: false,
            punchIn: null,
          },
          records: attendance?.records || [], // Merge the attendance records
          workingDays: attendance?.workingDays || {}, // Merge workingDays (0-6)
        }
      );

      return mergedEmployeeData;
    });
    // Set merged data into state
    setEmployeeData((prevData) => {
      const updatedData = {};
      mergedData.forEach((employee) => {
        updatedData[employee._id] = employee; // Use _id as the key, merged employee data as value
      });
      return updatedData;
    });
  };

  const isMarkAsPaidDisabled = () => {
    const selectedDate = DateTime.fromObject({
      month: selectedMonth + 1,
      year: selectedYear,
    });
    const currentDate = DateTime.local(); // Get the current date using Luxon

    // Disable if selected month/year is in the future
    return selectedDate > currentDate;
  };

  // Fetch data for the current page's employee IDs
  useEffect(() => {
    if (employeeIds.length > 0) {
      fetchEmployeeData(); // Fetch employee data if employee IDs are available
    }
  }, [employeeIds, selectedMonth, selectedYear, dailyRecords, policyData]);

  // Update employeeIds based on the current page and rowsPerPage
  useEffect(() => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const newEmployeeIds = employees
      ?.slice(startIndex, endIndex)
      .map((employee) => employee._id);
    setEmployeeIds(newEmployeeIds); // Set employee IDs to fetch data for the current page
  }, [page, rowsPerPage, employees]);

  const getStatusColor = (status) => {
    if (status === "Present") {
      return "#1e9ed9"; // Light blue for "Present"
    } else if (status === "Absent") {
      return "#e80c43"; // Light red for "Absent"
    } else if (status === "Weekend") {
      return "#ed8709"; // Light orange for "Weekend"
    }
    return "transparent"; // Default for empty status
  };

  const getBGColor = (status) => {
    if (status === "Present") {
      return "rgba(173, 216, 230, 0.2)"; // Light blue for "Present"
    } else if (status === "Absent") {
      return "#eddfe0"; // Light gray for "Absent"
    } else if (status === "Weekend") {
      return "#f0e5d8"; // Light yellow for "Weekend"
    }
    return "transparent"; // Default for empty status
  };

  const getStatusAbbreviation = (status) => {
    if (!status) return ""; // Return empty string if no status is available
    return status
      .split(" ") // Split the status by spaces
      .map((word) => word.charAt(0).toUpperCase()) // Get the first letter of each word and make it uppercase
      .join(""); // Join the letters back together
  };

  const daysInMonth = getDaysInMonth(selectedMonth, selectedYear);
  const handleUpdated = () => {
    refetch();
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseUpdateSalerySlip = ()=> setUpdatesalerySlipModel(false)

  const handlePaid = () => {
    setStatus(true);
  };
  return (
    <Box sx={{ padding: 0, width: "95%" }}>
      {/* Month and Year Select Dropdowns */}
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent:'space-between',
          width:'100%'
        }}
      >
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Add Payment Method</DialogTitle>
          <DialogContent>
            <AddPaymentMethodModal open={open} handleClose={handleClose} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
  open={updateSalerySlipModel}
  onClose={handleCloseUpdateSalerySlip}
  PaperProps={{
    sx: {
      width: '90%',  // Set width to 90% of the viewport
      height: '90%', // Set height to 90% of the viewport
      maxWidth: 'none', // Ensure no maximum width is applied
      resize: 'both',  // Enable resizing both width and height
      overflow: 'auto', // Allow scroll when content overflows
      position: 'relative', // Positioning context for resizing
    },
  }}
>
  <DialogTitle>Update Salary Slips</DialogTitle>
  <DialogContent sx={{ overflowY: 'auto', height: 'calc(80% - 64px)' }}>
    <UpdateSalerySlips employees={employees} month={selectedMonth} year={selectedYear} />
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseUpdateSalerySlip} color="secondary">
      Cancel
    </Button>
  </DialogActions>
</Dialog>


        <Box
          sx={{
            display: "flex",
            marginBottom: 2,
            gap: 1,
            width: "70%",
          }}
        >
          <FormControl sx={{ width: "48%", height: "30px" }} size="small">
            <InputLabel>Month</InputLabel>
            <Select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              label="Month"
              sx={{
                color: useTheme().palette.text.primary,
                maxHeight: "35px", // Height of the Select input
                minWidth: "120px", // Optional: Set a minimum width for the Select box
              }}
            >
              {months.map((_, index) => (
                <MenuItem key={index} value={index}>
                  {DateTime.fromObject({ month: index + 1 }).toFormat("MMM")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: "48%", height: "35px" }} size="small">
            <InputLabel>Year</InputLabel>
            <Select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              label="Year"
              sx={{
                maxHeight: "35px", // Height of the Select input
                minWidth: "120px", // Optional: Set a minimum width for the Select box
                fontSize: "14px", // Optional: Font size inside the Select box
                color: useTheme().palette.text.primary,
              }}
            >
              {years.map((year) => (
                <MenuItem
                  key={year}
                  value={year}
                  sx={{
                    height: "35px", // Height of each MenuItem
                    fontSize: "14px", // Font size for the MenuItem
                    display: "flex", // Ensure items are displayed as flex
                    alignItems: "center", // Vertically center the text
                  }}
                >
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            sx={{
              width: "50vh",
              color: useTheme().palette.text.primary,
              backgroundColor: useTheme().palette.primary.main,
              padding: "10px, 15px",
              ml: 0,
              color: "white",
              fontSize: "10px",
            }}
            onClick={() => setOpen(true)}
          >
            Add Payment Method
          </Button>
          <Button
            sx={{
              width: "50vh",
              color: useTheme().palette.text.primary,
              backgroundColor: useTheme().palette.primary.main,
              padding: "5px, 10px",
              ml: 0,
              color: "white",
              fontSize: "10px",
            }}
            onClick={handlePaid}
            disabled={paidStatus === true || isMarkAsPaidDisabled()}
          >
            Mark As Paid
          </Button>
        {user && user?.role === 'superAdmin' || user?.role === 'Admin' ? (
              <Button
              sx={{
                width: "50vh",
                color: useTheme().palette.text.primary,
                backgroundColor: useTheme().palette.primary.main,
                padding: "5px, 10px",
                ml: 0,
                color: "white",
                fontSize: "10px",
              }}
              onClick={()=>setUpdatesalerySlipModel(true)}
              disable = {selectedMonth == currentMonth}
            >
              Update Slips
            </Button>
        ) : null}
        </Box>
        <TablePagination
          component="div"
          count={employees.length}
          page={page}
          sx={{
            color: useTheme().palette.text.primary,
            width:'50vh'
          }}
          onPageChange={(e, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(e) =>
            setRowsPerPage(parseInt(e.target.value, 10))
          }
        />
      </Grid>
      {/* Table */}
      <Paper
        sx={{
          width: "105%",
          borderRadius: "15px",
          height: "73vh",
          overflow: "hidden",
          boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
          background: "rgba(255, 255, 255, 0.8)",
          backdropFilter: "blur(10px)",
          border: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <TableContainer
          sx={{
            maxHeight: "100%",
            width: "100%",
            "&::-webkit-scrollbar": {
              width: "8px",
              height: "2px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#4287f5",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "white",
              borderRadius: "4px",
            },
          }}
        >
          <Table>
            <TableHead
              sx={{
                backgroundColor: "white",
                position: "sticky",
                top: 0,
                zIndex: 9999,
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "20vh",
                    color: "black",
                    position: "sticky",
                    top: 0,
                    zIndex: 999999,
                    left: 0,
                    backgroundColor: "white",
                  }}
                >
                  Employee Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "20vh",
                    color: "black",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    backgroundColor: "white",
                  }}
                >
                  Total Present
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "20vh",
                    color: "black",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    backgroundColor: "white",
                  }}
                >
                  Total Absent
                </TableCell>
                {/* Dynamic Date Columns */}
                {daysInMonth.map((day, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "0.6rem",
                      border: "1px solid rgba(0, 0, 0, 0.1)",
                      color: "black",
                      zIndex: 99,
                    }}
                  >
                    <Typography sx={{ fontSize: "0.4rem", color: "black" }}>
                      {day}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {employees
                .slice(page * rowsPerPage, (page + 1) * rowsPerPage) // Pagination logic
                .map((employee) => {
                  const employeeData = Object.values(employeesData).find(
                    (emp) => emp.employeeId === employee._id
                  );
                  const workingDays = employeeData?.workingDays || {};

                  // Get today's date in the format 'yyyy-MM-dd'
                  const today = DateTime.now().toFormat("yyyy-MM-dd");
                  const nonWorkingday = daysInMonth
                  .map((day, dayIndex) => {
                    const dayOfWeek = getWeekdaysInMonth(selectedMonth, selectedYear)[dayIndex];
                    return {
                      date: DateTime.fromFormat(day, "d MMM yyyy").toFormat("yyyy-MM-dd"),
                      isNonWorking: workingDays[dayOfWeek] === false, // Non-working days where workingDay is false
                    };
                  })
                  .filter((val) => val.isNonWorking && val.date <= today);

                  const leave  = currentLeaves.filter((leave)=>{
                      return leave?.employeeId === employee?._id && leave?.status === "Approved"
                  })
                  
                  const currentDay = daysInMonth.map((val) => {
                    // First, convert the non-ISO formatted date (like "1 Nov 2024") to an ISO string
                    const formattedDate = DateTime.fromFormat(val, "d MMM yyyy").toFormat("yyyy-MM-dd");
                  
                  
                    return formattedDate;
                  }).filter((val) => {
                    // Convert the formatted date to DateTime object and compare it with today's date
                    const day = DateTime.fromISO(val);
                    return day <= DateTime.fromISO(today); // Compare DateTime objects
                  });
                 
                  return (
                    <TableRow
                      key={employee._id}
                      sx={{ border: "1px solid white" }}
                    >
                      <TableCell
                        sx={{
                          fontSize: "0.8rem",
                          width: "20vh",
                          color: "black",
                          position: "sticky",
                          top: 0,
                          left: 0,
                          zIndex: 999,
                          backgroundColor: "white",
                        }}
                      >
                        <Link
                          to={`/emplyoees/${employee._id}`}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          {employee.name}
                        </Link>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "0.8rem",
                          width: "20vh",
                          color: "black",
                          position: "sticky",
                          top: 0,
                          zIndex: 0,
                          backgroundColor: "white",
                        }}
                      >
                         <Typography sx={{
                          fontSize:'14px',
                          backgroundColor: '#bcd9e3',
                          color:'#19ade3',
                          padding:'6px 10px',
                          textAlign:'center',
                          borderRadius:'15px'
                         }}>
                         {employeeData?.records?.length}
                         </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "0.8rem",
                          width: "20vh",
                          color: "black",
                          position: "sticky",
                          top: 0,
                          backgroundColor: "white",
                        }}
                      >
                        <Typography sx={{
                          fontSize:'14px',
                          backgroundColor: '#f5c1d1',
                          color:'#e01955',
                          padding:'6px 10px',
                          textAlign:'center',
                          borderRadius:'15px'
                         }}>
                        {currentDay?.length -  (employeeData?.records?.length + nonWorkingday?.length + leave?.length)}
                        
                        </Typography>
                      </TableCell>

                      {/* Iterate over each day of the month */}
                      {daysInMonth.map((day, dayIndex) => {
                        const dayOfWeek = getWeekdaysInMonth(
                          selectedMonth,
                          selectedYear
                        )[dayIndex];

                        // Create DateTime object for the current day in 'yyyy-MM-dd' format
                        const date = DateTime.fromFormat(
                          daysInMonth[dayIndex],
                          "d MMM yyyy"
                        );
                        const formattedDate = date.toFormat("yyyy-MM-dd");

                        // Check if the date is in the past or today
                        if (formattedDate > today) {
                          // Skip rendering the table cell if the day is in the future
                          return (
                            <TableCell
                              key={dayIndex}
                              sx={{
                                zIndex: 1,
                                padding: "5px 10px",
                                textAlign: "center",
                                fontSize: "0.6rem",
                                border: "2px solid white",

                                zIndex: 1,
                              }}
                            >
                              <GetLeaveStatusAndAction
                                employee={employee}
                                date={formattedDate}
                                leaves={leaves}
                                updated={handleUpdated}
                              />
                            </TableCell>
                          );
                        }

                        // Check if it's a working day
                        const isWorkingDay = workingDays[dayOfWeek] === true;

                        // Check attendance record for the employee on this day
                        const attendanceRecord = employeeData?.records?.find(
                          (record) => record.day === formattedDate
                        );

                        // Determine the status of the employee on this day
                        const status = !attendanceRecord
                          ? isWorkingDay
                            ? "Absent"
                            : "Weekend"
                          : "Present";

                        return (
                          <TableCell
                            key={dayIndex}
                            sx={{
                              color: getStatusColor(status),
                              backgroundColor: getBGColor(status),
                              padding: "5px 10px",
                              textAlign: "center",
                              fontSize: "0.6rem",
                              border: "2px solid white",
                              zIndex: 1,
                            }}
                          >
                            <Typography sx={{ padding: 0, fontSize: "0.6rem" }}>
                              {getStatusAbbreviation(status)}
                              {
                                <GetLeaveStatusAndAction
                                  employee={employee}
                                  date={formattedDate}
                                  leaves={leaves}
                                  updated={handleUpdated}
                                />
                              }
                              {paymentDate && (
                                <GetPaymentDate
                                  paymentDate={paymentDate}
                                  date={dayIndex}
                                  status={paidStatus}
                                  selectedMonth={selectedMonth}
                                  selectedYear={selectedYear}
                                />
                              )}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Pagination Controls */}
    </Box>
  );
}

export default EmployeeTable;
