import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  MenuItem,
  Select,
  FormControl,
  styled,
  Grid,
  Collapse,
  IconButton,
  Tooltip,
  Popover,
  TextField,
  InputAdornment,
  Snackbar,
  Alert,
} from "@mui/material";
import { Rating } from "@mui/material";
import { useGet, usePost } from "../../../helpers/axios/useApi";
import { useAuth } from "../../../middlewares/auth";
import ObjectList from "./addinationDetails";
import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import GlassEffect from "../../../theme/glassEffect";

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    padding: "5px 8px",
    fontSize: "0.875rem",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    borderColor: theme.palette.grey[300],
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiInputLabel-root": {
    fontSize: "0.75rem",
  },
}));

const stages = [
  "Create",
  "Follow Up Required",
  "Booking",
  "Session",
  "Payment",
  "Documentation",
  "Success",
  "Not Interested",
  "DNP - Did Not Pick up",
  "Invalid Number",
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function TaskTabs({ tasks = [], leadID }) {
  const [value, setValue] = useState(0);
  const [selectedStage, setSelectedStage] = useState("");
  const [openTaskId, setOpenTaskId] = useState(null);
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [feedBack, setFeedBack] = useState({
    desc: "",
    points: 1,
    leadId: "",
    taskId: "",
  });
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [feedbackData, setFeedBackData] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleStageChange = (event) => {
    setSelectedStage(event.target.value);
  };

  const handleDescriptionToggle = (taskId) => {
    setOpenTaskId(openTaskId === taskId ? null : taskId);
  };

  const giveFeedBack = usePost("feedback/create");
  const { data: feedbacks, refetch } = useGet("feedback/get", {
    leadId: leadID,
  });

  useEffect(() => {
    if (feedbacks) {
      setFeedBackData(feedbacks?.data?.message);
    }
  }, [feedbacks]);

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const filteredTasks = tasks.filter((task) => {
    const isCalling = task.taskType.startsWith("Calling");
    return (
      selectedStage === "" ||
      (selectedStage === "Create" &&
        (isCalling || task.taskType === "Create")) ||
      task.taskType === selectedStage
    );
  });

  const { data: employees } = useGet(
    "employee/all",
    {},
    {},
    { queryKey: "employees" }
  );

  const getEmployeeName = (id) => {
    let employee = employees?.data?.message[0]?.find(
      (employee) => employee._id === `${id}`
    );
    return employee ? `${employee.name}` : null;
  };

  const handleClick = (event, taskId, leadId) => {
    setAnchorEl(event.currentTarget);
    setFeedBack({ ...feedBack, leadId, taskId });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFeedBack({ desc: "", points: 1 });
  };

  const handleSendFeedback = async () => {
    try {
      await giveFeedBack.mutateAsync(feedBack);
      setSnackMessage("Feedback submitted successfully!");
      refetch();
    } catch (error) {
      setSnackMessage("Failed to submit feedback. Please try again.");
    } finally {
      setSnackOpen(true);
      handleClose();
    }
  };

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "10px",
        mt: "10px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        padding: "10px",
        height: "80vh",
      }}
    >
      <GlassEffect.GlassContainer>
      <Tabs value={value} onChange={handleChange} aria-label="task tabs">
        <Tab label="Tasks" id="tab-0" aria-controls="tabpanel-0" />
        <Tab label="Payments" id="tab-1" aria-controls="tabpanel-1" />
       
      </Tabs>

      <TabPanel value={value} index={0}>
        <Grid sx={{ height: "70vh", overflowY: "scroll" }}>
          <Grid width={"30%"}>
            <FormControl fullWidth>
              <StyledSelect
                value={selectedStage}
                onChange={handleStageChange}
                size="small"
                displayEmpty
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {stages.map((stage) => (
                  <MenuItem key={stage} value={stage}>
                    {stage}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </Grid>
          <Grid>
            {filteredTasks.length > 0 ? (
              filteredTasks.map((task) => {
                // Find the feedback associated with the current task
                const taskFeedback =
                  feedbackData?.filter(
                    (feedback) => feedback.taskId === task._id
                  ) || [];

                return (
                  <Grid
                    key={task.id}
                    sx={{
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                      borderRadius: "10px",
                      padding: "10px",
                      backgroundColor: "white",
                      display: "flex",
                      flexDirection: "column",
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <Grid
                      display={"flex"}
                      justifyContent={"space-between"}
                      sx={{ mb: 2 }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.875rem",
                          fontWeight: "bold",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 1,
                          lineHeight: 1.5,
                          color: "grey",
                        }}
                      >
                        <span style={{ color: "black" }}>{task.taskType}</span>
                        {getEmployeeName(task.assignee) !==
                        getEmployeeName(task.createdBy) ? (
                          <>
                            was performed by{" "}
                            <span style={{ color: "black" }}>
                              {getEmployeeName(task.createdBy)}
                            </span>{" "}
                            on{" "}
                            <span style={{ color: "black" }}>
                              {formatDate(task.createdAt)}
                            </span>
                          </>
                        ) : (
                          <>
                            was created on{" "}
                            <span style={{ color: "black" }}>
                              {formatDate(task.createdAt)}
                            </span>
                          </>
                        )}
                      </Typography>
                    </Grid>

                    {task.status === "completed" && (
                      <Grid item xs={12} mt={-2.5}>
                        <Grid
                          display={"flex"}
                          justifyContent={"flex-start"}
                          sx={{ mb: 2 }}
                          gap={8}
                        >
                          <Typography
                            sx={{
                              fontSize: "0.875rem",
                              fontWeight: "bold",
                              ml: 2,
                              color: "grey",
                            }}
                          >
                            This stage was completed by{" "}
                            <span style={{ color: "black" }}>
                              {getEmployeeName(task.completedBy)}{" "}
                            </span>
                            On{" "}
                            <span style={{ color: "black" }}>
                              {formatDate(task.completedAt)}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    <Box mt={0}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold", textAlign: "left" }}
                      >
                        Feedbacks :
                      </Typography>
                      {taskFeedback.length > 0 ? (
                        taskFeedback.map((feedback) => (
                          <>
                            <Typography
                              key={feedback._id}
                              sx={{
                                fontSize: "0.865rem",
                                color: "black",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {/* Show stars for rating */}
                              Descripation : {feedback.desc}
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: "left",
                              }}
                            >
                              <Rating
                                value={feedback.points}
                                readOnly
                                sx={{ mr: 1, fontSize: "1rem" }}
                              />{" "}
                            </Typography>
                          </>
                        ))
                      ) : (
                        <Typography
                          sx={{ fontSize: "0.875rem", color: "grey" }}
                        >
                          No feedback available.
                        </Typography>
                      )}
                    </Box>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyItems: "space-between",
                        justifyContent: "space-between",
                        mt: -3,
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "0.875rem",
                            fontWeight: "bold",
                            textAlign: "left",
                            cursor: "pointer",
                            color: "#4287f5",
                            mt: 2,
                          }}
                          onClick={() => handleDescriptionToggle(task._id)}
                        >
                          {openTaskId === task._id ? (
                            <>Hide Details</>
                          ) : (
                            <>View Details</>
                          )}
                        </Typography>
                        <Collapse in={openTaskId === task._id}>
                          <Typography
                            sx={{
                              fontSize: "0.875rem",
                              mt: 1,
                              fontWeight: "Bold",
                            }}
                          >
                            {task.descripation}
                          </Typography>
                          <Grid width={"100%"}>
                            <ObjectList data={task?.customFields} />
                          </Grid>

                          {/* Render feedback for the task */}
                        </Collapse>
                      </Box>

                      <Box display={"flex"}>
                        <Typography
                          sx={{
                            fontSize: "0.875rem",
                            mt: 1,
                            height: "1.5rem",
                            width: "1.5rem",
                            backgroundColor: "#1c2d45",
                            textAlign: "center",
                            color: "white",
                            borderRadius: "50%",
                          }}
                        >
                          {task.points}
                        </Typography>
                        {["superAdmin", "Admin", "Manager"].includes(
                          user?.role
                        ) && (
                          <Tooltip title="Feedback">
                            <IconButton
                              onClick={(event) =>
                                handleClick(event, task._id, task?.leadId)
                              }
                            >
                              <ReplyIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Typography>
                No tasks available for the selected stage.
              </Typography>
            )}
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>

          
      </TabPanel>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          borderColor: "#ced1d6",
          borderRadius: "15px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          border: "none",
        }}
      >
        <Box sx={{ padding: 2, width: 300 }}>
          <TextField
            type="text"
            value={feedBack.desc}
            onChange={(e) => setFeedBack({ ...feedBack, desc: e.target.value })}
            placeholder="Enter feedback"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#ced1d6",
                },
                "&:hover fieldset": {
                  borderColor: "#398bf7",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#398bf7",
                },
              },
            }}
          />
          <Typography variant="body2" sx={{ mt: 1 }}>
            Rate Task:
          </Typography>
          <Rating
            name="feedback-points"
            value={feedBack.points}
            onChange={(event, newValue) =>
              setFeedBack({ ...feedBack, points: newValue })
            }
            sx={{ mb: 1 }}
          />
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              mb: 2,
            }}
          >
            <InputAdornment position="end">
              <IconButton
                onClick={handleSendFeedback}
                sx={{
                  "&:hover": {
                    color: "#038cfc",
                  },
                }}
              >
                <SendIcon />
              </IconButton>
            </InputAdornment>
          </Grid>
        </Box>
      </Popover>

      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          sx={{ width: "100%", mb: 20 }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
      </GlassEffect.GlassContainer>
    </Box>
  );
}

export default TaskTabs;
